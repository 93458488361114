<template>
  <div>
    <v-layout
          row
          wrap
          justify-center
        >
          <v-flex
            xs12
            class="pa-2"
          >
            <v-alert
              :value="alert.value"
              :type="alert.type"
            >
              {{ $t(alert.message) }}
            </v-alert>
          </v-flex>
          </v-layout>
    <v-data-table
      v-model="selected"
      :pagination.sync="pagination"
      :headers="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin') ? headersAdmin : headers"
      :items="parts"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25,50,100]"
      item-key="_id"
      select-all
      :loading="loading"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :class="{tableRowColorSelected : props.index === activeRow}"
          class="tableRowColor"
          @click="activeRow = props.index"
        >
          <!---------------------------------------SELECTION--------------------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <v-checkbox
                v-model="props.selected"
                class="mt-2"
                color="primary"
                hide-details
              />
            </v-layout>
          </td>
          <!------------------------------------------ID------------------------------------------->
          <!--OLD ID-->
          <td v-if="props.item.old_id && props.item.old_id !== undefined">
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item.old_id,
                type: 'inventory',
                car: props.item.car_id,
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item.old_id"
              />
              {{ props.item.old_id.slice(props.item.old_id.length-6, props.item.old_id.length) }}
            </v-layout>
          </td>
          <!--NEW ID-->
          <td
            v-else
            class="text-xs"
          >
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item._id,
                type: 'inventory',
                car_id: props.item.car_id,
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item._id"
              />
              {{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout row align-center>
                <v-btn icon @click="details(props.item)">
                  <v-icon color="grey darken-2">
                    view_list
                  </v-icon>
                </v-btn>
                <v-btn icon @click="actions(props.item)">
                  <v-icon color="grey darken-2">
                    compare_arrows
                  </v-icon>
                </v-btn>
                <v-btn icon @click="edit(props.item)">
                  <v-icon color="grey darken-2">
                    edit
                  </v-icon>
                </v-btn>
            </v-layout>
            <v-layout row align-center>
                <v-btn icon :disabled="!props.item.car_id" @click="viewCar(props.item.car_id)">
                  <v-icon color="grey darken-2">
                    directions_car
                  </v-icon>
                </v-btn>
                <v-btn icon @click="share(props.item)">
                  <v-icon color="grey darken-2">
                    share
                  </v-icon>
                </v-btn>
                <v-btn v-if="props.item.refusal_reason" icon @click="showRefusalReason(props.item.refusal_reason)">
                  <v-icon color="grey darken-2">
                    info
                  </v-icon>
                </v-btn>
                <v-btn v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin'" icon @click="deletePart(props.item._id, props.item.kromeda_id)">
                  <v-icon color="grey darken-2">
                    delete
                  </v-icon>
                </v-btn>
               </v-layout>
          </td>
  <!---------------------------------------SHIPMENT---------------------------------------->
  <td class="pa-2" style="min-width: 140px;">
  <span v-if="props.item.shipment_id">
    {{ props.item.shipment_id.name }}<br>
  </span>
            <span v-if="props.item.shelf_data">
    {{ props.item.shelf_data.shelf.room_name }} -
    {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},
    {{ props.item.shelf_data.shelf.y }}<br>
    <span class="caption">Mag: </span>
    <span class="font-weight-bold caption">{{ props.item.shelf_data.repo }}</span>
  </span>
            <span v-else>
    -
  </span>
          </td>
          <!--------------------------------------LEKO GROUP--------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.group"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.group[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------GROUP----------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.category"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.category[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout column>
              <v-flex v-if="props.item.infoCar && props.item.infoCar.description">
                <span
                  v-if="props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.title"
                  class="text-xs font-weight-bold"
                >
                  {{ props.item.infoCar.description.descriptionId.title[$store.getters.language] }}
                </span>
                <span
                  v-if="props.item.infoCar.description.positionCode"
                  class="text-xs"
                >
                  {{ getPosition(props.item.infoCar.description.positionCode) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------MANUFACTURER CODE----------------------------------->
          <td class="text-xs">
<!--            {{ props.item.infoCar.oemCode }}-->
            {{ props.item.manufacturer_code }}
          </td>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <td
            v-if="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin') && props.item.institution_id"
            class="text-xs"
          >
            {{ props.item.institution_id.title }}
          </td>
          <td v-else-if="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin') && !props.item.institution_id" />
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout align-center justify-center>
              <img
                :src="`${server}/images?q=${props.item.images && props.item.images.length ? props.item.images[0].thumb : ''}`"
                class="pointer"
                height="75"
                @click="showImages(props.item.images)"
              >
            </v-layout>
          </td>
            <!-------------------------------------CONDITION-------------------------------------->
            <td class="text-xs">
              <span v-if="props.item.isNewPart">
                <v-icon>mdi-check-circle-outline</v-icon>
              </span>
          </td>
          <!-------------------------------------INFOCAR CODE-------------------------------------->
          <td class="text-xs">
           {{ props.item.infoCar.oemCode }}
          </td>
           <!-----------------------------------------PRICE----------------------------------------->
           <td class="text-xs">
            <v-layout
              row
              align-center
              justify-center
            >
              <v-flex>
                <div>
                  {{ props.item.price }} {{ props.item.currency }}
                </div>
              </v-flex>
              <v-flex>
                <!--EDIT-->
                <v-edit-dialog
                  large
                  return-value.sync="props.item.price"
                  lazy
                  @save="savePrice(props.item, props.item.price)"
                  @cancel="cancelPrice"
                >
                  <v-btn icon>
                    <v-icon color="grey darken-2">
                      edit
                    </v-icon>
                  </v-btn>

                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.price"
                      :disabled="$store.getters.role === 'User'"
                      color="red darken-1"
                      type="number"
                      step="0.01"
                      min="0"
                    />
                  </template>
                </v-edit-dialog>
              </v-flex>
              <v-btn v-if="props.item.infoCar && props.item.infoCar.oemCode" icon 
                     @click="openPriceAverages(props.item.infoCar.oemCode)" outline small>
                <v-icon color="grey darken-2" size="small">euro</v-icon></v-btn>
            </v-layout>
          </td>
          <!--------------------------------------PRA NUMBER--------------------------------------->
          <td class="text-xs">
            {{ props.item.pra }}
          </td>
          <!-----------------------------------------BRAND----------------------------------------->
          <td class="text-xs">
            {{ props.item.brand }}
          </td>
          <!---------------------------------------EBAY Category--------------------------------------->
          <td class="text-xs">
            {{ props.item.ebayCode }}
            <br> <small v-if="props.item.infoCar && props.item.infoCar.description && props.item.infoCar.description.descriptionId && props.item.infoCar.description.descriptionId.ebayCategory"> 
              <strong> {{$t('parts.inventory.ebay_category')}}</strong>
              {{ props.item.infoCar.description.descriptionId.ebayCategory ? props.item.infoCar.description.descriptionId.ebayCategory[$store.getters.language] : '' }}
           </small> 
        </td> 
        <!---------------------------------------EBAY Title--------------------------------------->
        <td class="text-xs">
            <br> <small v-if="props.item && props.item.eBayStatus == true" class="d-flex"> 
              {{ generateTitle(props.item) }}
              <v-btn :disabled="!$store.getters.institutionExportEbayLeko || (($store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin') && !props.item.institution_id.export_ebay_leko)"
              @click="openEditTitleEbay(props.item)" class="pa-0 ma-0"  icon >
                    <v-icon class="pa-0 ma-0" color="grey darken-2">
                      edit
                    </v-icon>
                  </v-btn>
           </small> 
        </td> 
             <!---------------------------------------Google--------------------------------------->
             <td class="text-xs" >
              <span class="d-flex" style="width: 100px !important">
                <v-icon v-if="props.item.googleMerchantStatus == true">mdi-check-circle-outline</v-icon>
                <v-btn 
                  v-if="props.item.googleMerchantStatus == false"
                  icon 
                  :disabled="!$store.getters.institutionExportGoogleLeko || (($store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin') && !props.item.institution_id.export_google_merchant_leko)"
                  @click="openAddGoogle(props.item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn 
                  v-if="props.item.googleMerchantStatus == true"
                  class="ml-0 pl-0"
                  icon 
                  :disabled="!$store.getters.institutionExportGoogleLeko || (($store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin') && !props.item.institution_id.export_google_merchant_leko)"
                  @click="openDeleteGoogle(props.item)"
                > 
                  <v-icon  class="justify-left">mdi-delete</v-icon>
                </v-btn>
             </span>
             <!-- <a @click="openGoogleLink(props.item)" v-if="props.item && props.item.googleMerchantStatus == true" role="button" class="pt-1" > {{ $t('parts.inventory.see_google') }}</a> -->
          
        </td> 
           <!-------------------------------------EBAY ACTIONS------------------------------------->
           <td class="text-xs">
            <!-- <v-icon v-if="props.item.ebayCode">edit</v-icon> -->
             <span class="d-flex">
                <v-icon v-if="props.item && props.item.eBayStatus == true">mdi-check-circle-outline</v-icon>
                <v-btn 
                  v-if="props.item && props.item.eBayStatus == false"
                  icon 
                  :disabled="!$store.getters.institutionExportEbayLeko || (($store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin') && !props.item.institution_id.export_ebay_leko)"
                  @click="openAddEbay(props.item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn 
                  v-if="props.item && props.item.eBayStatus == true"
                  icon 
                  :disabled="!$store.getters.institutionExportEbayLeko"
                  @click="openDeleteEbay(props.item)"
                > 
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
             </span>
             <a @click="openEbayLink(props.item)" role="button" class="pt-1" v-if="props.item && props.item.eBayStatus == true"> {{ $t('parts.inventory.see_ebay') }}</a>
            </td>
         
                  <!---------------------------------------kTypes--------------------------------------->
          <td class="text-xs">
            <span class="d-flex">
            <v-icon  v-if="props.item && props.item.infoCar && props.item.infoCar.kTypes && props.item.infoCar.kTypes.length > 0" @click="checkKtypes(props.item)">mdi-check-circle-outline</v-icon>
            <v-icon v-else >mdi-cancel</v-icon>
            <p class="mb-0 ml-1 mt-1">
              {{ props.item.infoCar && props.item.infoCar.kTypes && props.item.infoCar.kTypes.length > 0 ? '(' + props.item.infoCar.kTypes.length + ')': '' }}
            </p>
          </span>
          </td> 
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            <v-layout
              row
              align-center
              justify-center
              class="pointer"
              @click="showNotes(props.item.notes)" style="width: 100px !important"
            >
              <v-flex>
                <span v-if="props.item.notes && props.item.notes.length > 40">
                  {{ props.item.notes.substring(0, 40) }} ...
                </span>
                <span v-else> {{ props.item.notes }} </span>
              </v-flex>
              <v-flex v-if="props.item.notes && props.item.notes.length > 40">
                <v-icon>visibility</v-icon>
              </v-flex>
            </v-layout>
          </td>
         
          <!---------------------------------------LOCATION---------------------------------------->
<!--          <td-->
<!--            v-if="props.item.shelf_data"-->
<!--            class="text-xs"-->
<!--          >-->
<!--            <span>-->
<!--              {{ props.item.shelf_data.shelf.room_name }} - -->
<!--              {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},-->
<!--              {{ props.item.shelf_data.shelf.y }}-->
<!--            </span>-->

<!--            <div class="text-no-wrap ">-->
<!--              <span class="caption">Mag: </span>-->
<!--              <span class="font-weight-bold caption">{{ props.item.shelf_data.repo }}</span>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td-->
<!--            v-else-->
<!--            class="text-xs"-->
<!--          >-->
<!--            - -->
<!--          </td>-->
        


          <!----------------------------------------QUALITY---------------------------------------->
<!--          <td v-if="props.item.quality">-->
<!--            <div style="width: 50px">-->
<!--              <v-rating-->
<!--                :value="props.item.quality.rating"-->
<!--                color="red"-->
<!--                background-color="red darken-4"-->
<!--                readonly-->
<!--                dense-->
<!--                size="12"-->
<!--              />-->
<!--            </div>-->
<!--          </td>-->


          <!-----------------------------------------DATE------------------------------------------>
<!--          <td>{{ props.item.date_created | moment }}</td>-->
          <!----------------------------------------STATUS----------------------------------------->
          <div class="pt-2 pb-1">
          <td v-if="props.item.quality">
            <div style="width: 140px; margin-right: 16px;">
              <v-rating
                :value="props.item.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                size="14"
              />
            </div>
            <div style="display: block;" class="pl-2 pb-1">{{ props.item.date_created | moment('YYYYMMDD') }}</div>
            <v-layout
              align-center
              justify-left>
              <v-btn v-if="props.item.status === 1" small color="success" outline @click="movement(props.item)">
                {{ $t('parts.status.entered') }}
              </v-btn>
              <v-btn v-if="props.item.status === 2" small color="error" outline @click="movement(props.item)">
                {{ $t('parts.status.returned') }}
              </v-btn>
              <v-btn v-if="props.item.status === 2.1" small color="error" outline @click="movement(props.item)">
                {{ $t('parts.status.returned_shop') }}
              </v-btn>
              <v-btn v-if="props.item.status === 3" small color="info" outline @click="movement(props.item)">
                {{ $t('parts.status.re_entered') }}
              </v-btn>
              <v-btn v-if="props.item.status === 4" small color="info" outline @click="movement(props.item)">
                {{ $t('parts.status.found') }}
              </v-btn>
            </v-layout>
          </td>
          </div>
          <!--------------------------------------------------------------------------------------->
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="loadingPage"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';
import axios from 'axios';
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';
import putRequest from '../../api/putRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    loadingPage: false,
    activeRow: null,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    selected: [],
    totalItems: 0,
    parts: [],
    headers: [
      {
        text: '',
        sortable: true,
        value: '_id',
      },
      {
        text: '',
        sortable: false,
        value: 'actions',
      },
      {
        text: '',
        sortable: true,
        value: 'shipment_id',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: '',
        sortable: true,
        value: 'group',
      },
      {
        text: '',
        sortable: true,
        value: 'infoCar.description.descriptionId.title.it',
      },
      {
        text: '',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: '',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: false,
        value: 'isNewPart',
      },
      {
        text: '',
        sortable: false,
        value: 'oemCode',
      },
      {
        text: '',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: true,
        value: 'pra',
      },
      {
        text: '',
        sortable: true,
        value: 'brand',
      },
      {
        text: '',
        sortable: true,
        value: 'ebayCode',
      },
      {
        text: '',
        sortable: true,
        value: 'eBayTitle',
      },
      {
      text: '',
      sortable: true,
      value: 'Google',
      },
      {
      text: '',
      sortable: true,
      value: 'eBay',
      },
      {
      text: '',
      sortable: true,
      value: 'kTypes',
      },
      {
        text: '',
        sortable: false,
        value: 'notes',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'shelf_data.shelf.room_name',
      // },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
    headersAdmin: [
      {
        text: '',
        sortable: true,
        value: '_id',
      },
      {
        text: '',
        sortable: false,
        value: 'actions',
      },
      {
        text: '',
        sortable: true,
        value: 'shipment_id',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: '',
        sortable: true,
        value: 'group',
      },
      {
        text: '',
        sortable: true,
        value: 'infoCar.description.descriptionId.title.it',
      },
      {
        text: '',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: '',
        sortable: false,
        value: 'institution_id',
      },
      {
        text: '',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: false,
        value: 'isNewPart',
      },
      {
        text: '',
        sortable: false,
        value: 'oemCode',
      },
      {
        text: '',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: true,
        value: 'pra',
      },
      {
        text: '',
        sortable: true,
        value: 'brand',
      },
      {
        text: '',
        sortable: true,
        value: 'ebayCode',
      },
      {
        text: '',
        sortable: true,
        value: 'eBayTitle',
      },
      {
      text: '',
      sortable: true,
      value: 'Google',
      },
      {
        text: '',
        sortable: true,
        value: 'ebay',
      },
      {
        text: '',
        sortable: true,
        value: 'kTypes',
      },
      {
        text: '',
        sortable: false,
        value: 'notes',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'shelf_data.shelf.room_name',
      // },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    // baseUrl: 'http://localhost:3000', 
    // baseUrl: 'https://api.lekotech.com',
  }),
  computed: {
    server() { return this.$serverUri; },
    searchObject() { return this.$props.search; },
    filteredItemsByStatusAndDate() {
      const desiredStatusValue = 2;
      const desiredDate = moment('2023-04-19');

      return this.items.filter(item => {
        const itemDate = moment(item.date_created, 'YYYYMMDD');
        return item.status === desiredStatusValue && itemDate.isSame(desiredDate, 'day');
      });
    },
  },
  watch: {
    selected: {
      handler(data) {
        if (data.length > 0) {
          this.$emit('selected', { value: true, items: data });
        } else {
          this.$emit('selected', { value: false, items: data });
        }
      },
    },
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getParts();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getParts();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    parts(value) {
      this.$emit('items', value);
    },
  },
  beforeMount() {
    this.getLocale();
  },
  methods: {
     openPriceAverages(code){
      this.$emit('average-prices', code);
    },
    openAddEbay(item){
      this.$emit('add-ebay', item);
    },
    openDeleteEbay(item){
      this.$emit('delete-ebay', item);
    },
    openAddGoogle(item){
      this.$emit('add-google', item);
    },
    openDeleteGoogle(item){
      this.$emit('delete-google', item);
    },
    checkKtypes(item){
      this.$emit('check-ktypes', item);
    },
    openEditTitleEbay(item){
      this.$emit('edit-ebay-title', item);
    },
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.table.qr_code');
      this.headers[1].text = this.$i18n.t('parts.table.actions');
      this.headers[2].text = this.$i18n.t('parts.table.shipment');
      this.headers[3].text = this.$i18n.t('parts.table.leko_group');
      this.headers[4].text = this.$i18n.t('parts.table.group');
      this.headers[5].text = this.$i18n.t('parts.table.title');
      this.headers[6].text = this.$i18n.t('parts.table.manufacturer_code');
      this.headers[7].text = this.$i18n.t('parts.table.images');
      this.headers[8].text = this.$i18n.t('parts.table.new');
      this.headers[9].text = this.$i18n.t('parts.table.oem_code');
      this.headers[10].text = this.$i18n.t('parts.table.price');
      this.headers[11].text = this.$i18n.t('parts.table.pra');
      this.headers[12].text = this.$i18n.t('parts.table.brand');
      this.headers[13].text = this.$i18n.t('parts.table.ebay_code');
      this.headers[14].text = this.$i18n.t('parts.table.ebay_title');
      this.headers[15].text = this.$i18n.t('parts.table.google');
      this.headers[16].text = this.$i18n.t('parts.table.ebay');
      this.headers[17].text = this.$i18n.t('parts.table.kTypes');
      this.headers[18].text = this.$i18n.t('parts.table.notes');
      // this.headers[11].text = this.$i18n.t('parts.table.location');  
      // this.headers[13].text = this.$i18n.t('parts.table.quality');
      this.headers[19].text = this.$i18n.t('parts.table.date_created');
      // this.headers[13].text = this.$i18n.t('parts.table.status');
      this.headersAdmin[0].text = this.$i18n.t('parts.table.qr_code');
      this.headersAdmin[1].text = this.$i18n.t('parts.table.actions');
      this.headersAdmin[2].text = this.$i18n.t('parts.table.shipment');
      this.headersAdmin[3].text = this.$i18n.t('parts.table.leko_group');
      this.headersAdmin[4].text = this.$i18n.t('parts.table.group');
      this.headersAdmin[5].text = this.$i18n.t('parts.table.title');
      this.headersAdmin[6].text = this.$i18n.t('parts.table.manufacturer_code');
      this.headersAdmin[7].text = this.$i18n.t('parts.table.institution');
      this.headersAdmin[8].text = this.$i18n.t('parts.table.images');
      this.headersAdmin[9].text = this.$i18n.t('parts.table.new');
      this.headersAdmin[10].text = this.$i18n.t('parts.table.oem_code');
      this.headersAdmin[11].text = this.$i18n.t('parts.table.price');
      this.headersAdmin[12].text = this.$i18n.t('parts.table.pra');
      this.headersAdmin[13].text = this.$i18n.t('parts.table.brand');
      this.headersAdmin[14].text = this.$i18n.t('parts.table.ebay_code');
      this.headersAdmin[15].text = this.$i18n.t('parts.table.ebay_title');
      this.headersAdmin[16].text = this.$i18n.t('parts.table.google') ;
      this.headersAdmin[17].text = this.$i18n.t('parts.table.ebay');
      this.headersAdmin[18].text = this.$i18n.t('parts.table.kTypes');
      this.headersAdmin[19].text = this.$i18n.t('parts.table.notes');
      this.headersAdmin[20].text = this.$i18n.t('parts.table.price');
      // this.headersAdmin[11].text = this.$i18n.t('parts.table.location');     
      // this.headersAdmin[14].text = this.$i18n.t('parts.table.quality');
      this.headersAdmin[21].text = this.$i18n.t('parts.table.date_created');
      // this.headersAdmin[14].text = this.$i18n.t('parts.table.status');
    },
    getParts() {
      this.loading = true;
      getRequest(
        '/part/inventory/datatable',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.loading = false;
        });
    },
    regularSearch(data) {
      this.loading = true;
      getRequest(
        '/part/inventory/search/regular',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&language=${this.$store.getters.language}`,
      )
        .then((response) => {
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    advancedSearch(data) {
      this.loading = true;
      getRequest(
        '/part/inventory/search/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&brand=${data.advancedSearchFields.brand
        }&positionCode=${data.advancedSearchFields.positionCode
        }&eBayStatus=${data.advancedSearchFields.eBayStatus
        }&isNewPart=${data.advancedSearchFields.isNewPart
        }&no_shipping_cost=${data.advancedSearchFields.no_shipping_cost
        }&invalid_category=${data.advancedSearchFields.invalid_category
        }&model=${data.advancedSearchFields.model
        }&version=${data.advancedSearchFields.version
        }&institution=${data.advancedSearchFields.institution
        }&shipment=${data.advancedSearchFields.shipment
        }&status=${data.advancedSearchFields.status
        }&quality=${data.advancedSearchFields.quality
        }&price=${data.advancedSearchFields.price
        }&start_date=${data.advancedSearchFields.startDate
        }&end_date=${data.advancedSearchFields.endDate
        }&no_applicability=${data.advancedSearchFields.noApplicability
        }&no_notes=${data.advancedSearchFields.noNotes}`,
      )
        .then((response) => {
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;

          // if the brand and model are specified but there are no results, check if there are any
          // inserted vehicles with the specified details.
          if (response.count === 0) {
            this.checkVehicles(data.advancedSearchFields.brand, data.advancedSearchFields.model);
          }
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    deletePart(id, kromeda) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest(
          `/part/inventory/${id}`,
          kromeda
            ? `kromeda_id=${kromeda._id}&user_id=${this.$store.getters.userId
            }&institution_id=${this.$store.getters.institutionId}`
            : `user_id=${this.$store.getters.userId
            }&institution_id=${this.$store.getters.institutionId}`,
        )
          .then((response) => { this.$emit('delete'); })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loading = false; });
      }
    },
    checkVehicles(brand, model) {
      if (brand && model) {
        getRequest('/car/count', `brand=${brand}&model=${model}&institution_id=${this.$store.getters.institutionId}`)
          .then((response) => { if (response.count > 0) this.$emit('show-cars', { brand, model }); })
          .catch((error) => { console.log(error); })
          .finally(() => {});
      }
    },
    edit(data) {
      this.$emit('edit', data);
    },
    share(data) {
      this.$emit('share', data);
    },
    actions(data) {
      this.$emit('actions', { part: data });
    },
    details(data) {
      this.$emit('details', data);
    },
    movement(data) {
      this.$emit('movement', data);
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    showImages(images) {
      this.$emit('images', { type: 'string', images });
    },
    viewCar(carId) {
      this.$emit('view-car', { carId });
    },
    updatePagination() {
      this.activeRow = null;
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
     savePrice(item, price) {
      this.loadingPage = true;
      putRequest('/part/price', `id=${item._id}`, { price })
        .then((response) => {
          if(this.$store.getters.institutionExportEbayLeko && item.eBayStatus){
            if(response.data && response.data.ebayError && response.data.ebayError != null){
              this.$emit('show-error-modal', response.data.ebayError);
            }
          }          
           this.refreshParts(); 
          })
        .catch((error) => { console.log('error', error); })
        .finally(() => {
          this.loadingPage = false;
        });
    },
    cancelPrice() { this.refreshParts(); },
    refreshParts() {
      if (this.searchObject.type === null) {
        this.getParts();
      } else if (this.searchObject.type === 'regularSearch') {
        this.regularSearch(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      } else {
        console.log('search type is invalid');
      }
    },
    showNotes(notes) {
      this.$emit('show-notes', notes);
    },
    showRefusalReason(reason) {
      this.$emit('show-refusal-reason', reason);
    },
    clearSelected() { // invoked from the 'Inventory' view
      this.selected = [];
    },
    openEbayLink(item) {
      this.$emit('see-ebay-items', item); //still in progress
      // let url = `https://www.ebay.it/itm/${item.eBayConfirmationCode}`
      // window.open(url,'_blank');
    },
    openGoogleLink(item) {
      //TBD
      // let url = ``
      // window.open(url,'_blank');
    },
    generateTitle(item) {
      if (!item) return '';
      if (item.eBayTitle) return item.eBayTitle;

      const language = this.$store.getters.language;
      const manufacturerCode = item.infoCar && item.infoCar.oemCode ? item.infoCar.oemCode : '';
      const descriptionTitle = item.infoCar && item.infoCar.description && item.infoCar.description.descriptionId && item.infoCar.description.descriptionId.title ? item.infoCar.description.descriptionId.title[language] : '';
      const position = item.infoCar && item.infoCar.description && item.infoCar.description.positionCode ? this.getPosition(item.infoCar.description.positionCode) : '';
      const qr = item.old_id.slice(-6)
      return `${manufacturerCode} ${descriptionTitle} ${position} ${qr}`.trim();
    },
  },
};
</script>
