<template>
  <div>
    <v-data-table
      :items="computedParts"
      :pagination.sync="pagination"
      :total-items="totalItems"
      :headers="headers"
      :expand="expand"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      item-key="_id"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />

      <template
        slot="group"
        slot-scope="props"
      >
        <v-layout
          align-center
          row
        >
          <span
            v-if="props.groupName"
            class="ml-5 mr-2"
          >{{ $t('insert_parts.kit_qr') }}</span>
          <span
            v-if="props.groupName"
            class="ma-1"
          >
            <qr-code
              :size="50"
              :text="props.groupName"
            />
          </span>
        </v-layout>
      </template>
      <!----------------------------------------MAIN TABLE----------------------------------------->
      <template v-slot:items="props">
        <tr
          :class="{ tableRowColorSelected: props.index == activeRow }"
          class="tableRowColor"
          @click="{
            activeRow = props.index,
            props.expanded = !props.expanded,
            expandedKit = { value: props.expanded, id: props.item.kit_id },
            getAdditionalParts(props.item.kit_id, props.expanded)
          }"
        >
          <!----------------------------------------QR CODE---------------------------------------->
          <td class="ma-1 pt-1 pb-1">
            <v-layout
              class="pointer"
              ma-1
              column
              align-center
              justify-center
              @click="openQrDialog(props.item._id)"
            >
              <qr-code
                :size="70"
                :text="props.item._id"
              />
              <h4>{{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}</h4>
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td class="justify-center">
            <v-layout row>
              <v-btn
                icon
                @click="openActionsDialog(props.item)"
              >
                <v-icon color="grey darken-2">
                  compare_arrows
                </v-icon>
              </v-btn>
            </v-layout>
          </td>
          <!-------------------------------------AVAILABILITY-------------------------------------->
          <td v-if="props.item.active">
            <v-chip
              label
              outline
              color="error"
            >
              {{ $t('leko_employees.approve_parts.table.unavailable') }}
            </v-chip>
          </td>
          <td v-else>
            <v-chip
              label
              outline
              color="success"
            >
              {{ $t('leko_employees.approve_parts.table.available') }}
            </v-chip>
          </td>
          <!--------------------------------SENT FOR APPROVAL DATE--------------------------------->
          <td>
            <span v-if="props.item.sentForApproval">
              {{ props.item.sentForApproval.date | moment }}
            </span>
          </td>
          <!------------------------------------------OEM------------------------------------------>
          <td>
            <v-icon v-if="props.item.oemCodes && props.item.oemCodes.length > 0">
              check_circle
            </v-icon>
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <img
              :src="`${server}/images?q=${props.item.images && props.item.images.length
                ? props.item.images[0].thumb : ''}`"
              class="ma-1 pointer"
              height="75"
              width="100"
              @click="showImages(props.item._id, props.item.images, props.item.date_created)"
            >
          </td>
          <!--------------------------------CONDITION NEW--------------------------------->
          <td class="text-xs-center">
            <span v-if="props.item.isNewPart">
              <v-icon>mdi-check-circle-outline</v-icon>
            </span>
          </td>
          <!--------------------------------CLASSIFICATION WITH AI--------------------------------->
          <td class="text-xs-center">
            {{ props.item.classificationWithAI }}
          </td>
          <!------------------------------------------PRA------------------------------------------>
          <td class="text-xs">
            {{ props.item.pra }}
          </td>
          <!---------------------------------------SHIPMENT---------------------------------------->
          <td
            v-if="props.item.shipment_id"
            class="text-xs"
          >
            <a
              v-if="props.item.car_id"
              @click="viewCar(props.item.car_id._id)"
            > {{ props.item.shipment_id.name }} </a>
            <span v-else> {{ props.item.shipment_id.name }} </span>
          </td>
          <td
            v-else
            class="text-xs"
          />
          <!------------------------------------------CAD------------------------------------------>
          <td class="text-xs">
            <v-btn
              v-if="props.item.car_id"
              icon
              @click="openInfoCarCad(props.item.car_id)"
            >
              <v-icon>directions_car</v-icon>
            </v-btn>
          </td>
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            {{ props.item.notes }}
          </td>

          <!--------------------------------------INSTITUTION-------------------------------------->
          <td
            v-if="props.item.institution_id"
            class="text-xs"
          >
            {{ props.item.institution_id.title }}
          </td>
          <td
            v-else
            class="text-xs"
          />
          <!-----------------------------------------USER------------------------------------------>
          <td
            v-if="props.item.user_id"
            class="text-xs"
          >
            {{ props.item.user_id.name }}
          </td>
          <td
            v-else
            class="text-xs"
          />
          <td
            v-if="props.item.user_id"
            class="text-xs"
          >
            {{ props.item.user_id.surname }}
          </td>
          <td
            v-else
            class="text-xs"
          />
          <!-----------------------------------------AUDIO----------------------------------------->
          <!-- <td class="text-xs-center">
            <v-layout>
              <v-btn
                v-if="props.item.audio && !props.item.loadAudio"
                outline
                @click="props.item.loadAudio = true"
              >
                {{ $t('leko_employees.approve_parts.table.load_recording') }}
                <v-icon right>
                  mic
                </v-icon>
              </v-btn>
              <audio
                v-if="props.item.loadAudio"
                :src="`${server}/audio/?q=${props.item.audio}`"
                controls
              />
            </v-layout>
          </td> -->
        <!----------------------------------------------------------------------------------------->
        </tr>
      </template>
      <!----------------------------------KIT EXPANDED DATATABLE----------------------------------->
      <!------------------THIS THING HAS BEEN DEPRECATED FOR A LOOONG TIME BY NOW------------------>
      <template
        v-if="props.item.kit"
        slot="expand"
        slot-scope="props"
      >
        <div class="tableBorder">
          <v-data-table
            :headers="headers"
            :items="additionalParts"
            :loading="loadingAdditional"
            hide-actions
            class="tableRowColor"
          >
            <v-progress-linear
              slot="progress"
              color="red"
              indeterminate
            />
            <template
              slot="items"
              slot-scope="props"
            >
              <!--------------------------------------QR CODE-------------------------------------->
              <td class="ma-1 pt-1 pb-1">
                <v-layout
                  class="pointer"
                  ma-1
                  column
                  align-center
                  justify-center
                  @click="openQrDialog(props.item._id)"
                >
                  <qr-code
                    :size="70"
                    :text="props.item._id"
                  />
                  <h4>
                    {{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}
                  </h4>
                </v-layout>
              </td>
              <!--------------------------------------ACTIONS-------------------------------------->
              <td class="justify-center">
                <v-layout row>
                  <v-btn
                    icon
                    @click="openActionsDialog(props.item)"
                  >
                    <v-icon color="grey darken-2">
                      compare_arrows
                    </v-icon>
                  </v-btn>
                </v-layout>
              </td>
              <!-----------------------------------AVAILABILITY------------------------------------>
              <td v-if="props.item.active">
                <v-chip
                  label
                  outline
                  color="error"
                >
                  {{ $t('leko_employees.approve_parts.table.unavailable') }}
                </v-chip>
              </td>
              <td v-else>
                <v-chip
                  label
                  outline
                  color="success"
                >
                  {{ $t('leko_employees.approve_parts.table.available') }}
                </v-chip>
              </td>
              <!--------------------------------------IMAGES--------------------------------------->
              <td>
                <img
                  :src="`${server}/images/thumb?q=${props.item._id}-${props.item.images[0]
                  }&date=${props.item.date_created}`"
                  class="ma-1 pointer"
                  height="75"
                  width="100"
                  @click="showImages(props.item._id, props.item.images, props.item.date_created)"
                >
              </td>
              <!-------------------------------------SHIPMENT-------------------------------------->
              <td
                v-if="props.item.shipment_id"
                class="text-xs"
              >
                <a
                  v-if="props.item.car_id"
                  @click="viewCar(props.item.car_id._id)"
                > {{ props.item.shipment_id.name }} </a>
                <span v-else> {{ props.item.shipment_id.name }} </span>
              </td>
              <td
                v-else
                class="text-xs"
              />
              <!------------------------------------INSTITUTION------------------------------------>
              <td
                v-if="props.item.institution_id"
                class="text-xs"
              >
                {{ props.item.institution_id.title }}
              </td>
              <td
                v-else
                class="text-xs"
              />
              <!---------------------------------------USER---------------------------------------->
              <td
                v-if="props.item.user_id"
                class="text-xs"
              >
                {{ props.item.user_id.name }}
              </td>
              <td
                v-else
                class="text-xs"
              />
              <td
                v-if="props.item.user_id"
                class="text-xs"
              >
                {{ props.item.user_id.surname }}
              </td>
              <td
                v-else
                class="text-xs"
              />
              <!---------------------------------------AUDIO--------------------------------------->
              <!-- <td class="text-xs-center">
                <v-layout>
                  <v-btn
                    v-if="props.item.audio && props.item.audio.exists && !props.item.loadAudio"
                    outline
                    @click="props.item.loadAudio = true"
                  >
                    {{ $t('temporary_parts.load_recording') }}
                    <v-icon right>
                      mic
                    </v-icon>
                  </v-btn>
                  <audio
                    v-if="props.item.loadAudio"
                    :src="`${server}/audio/?q=${props.item._id}-${props.item.audio.title
                    }&date=${props.item.date_created}`"
                    controls
                  />
                </v-layout>
              </td> -->
              <!----------------------------------------------------------------------------------->
            </template>
          </v-data-table>
        </div>
      </template>
      <!------------------------------------------------------------------------------------------->
    </v-data-table>
    <v-dialog
      v-model="getCountedModal"
      width="480"
    >
      <v-card flat>
        <v-card-text>
          <div v-if="loadingAll">
            <v-progress-circular
              color="#D32F2F"
              indeterminate
              size="100"
            />
          </div>
          <div v-else>
            <div>
              <h2>
                {{ $t('leko_employees.approve_parts.waiting_for_approval') }}
              </h2>
            </div>
            <v-list>
              <v-list-item-group
                v-for="(inst, index) in counted"
                :key="index"
              >
                <v-list-item
                  :key="index"
                  class="mb-2"
                >
                  <v-list-item-content class="d-flex  mb-2 col-12">
                    <v-list-item-title class="font-weight-bold col-10">
                      {{ inst.title }}
                    </v-list-item-title>
                    <v-list-item-action
                      class="col-2 text-end"
                      style="justify-content: end; display: flex;"
                    >
                      <v-chip
                        color="red"
                        text-color="white"
                        class="ma-1 "
                      >
                        {{ inst.count }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item-content>
                </v-list-item>
                <hr>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    activeRow: null,
    expand: false,
    loading: false,
    loadingAdditional: false,
    headers: [
      {
        text: 'Qr Code',
        sortable: true,
        value: '_id',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '',
      },
      {
        text: 'Availability',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Sent For Approval',
        sortable: true,
        value: 'sentForApproval.date',
      },
      {
        text: 'OEM',
        sortable: false,
        value: 'oemCodes',
      },
      {
        text: 'Images',
        sortable: false,
        value: '',
      },
      {
        text: 'New',
        sortable: false,
        value: 'isNewPart',
      },
      {
        text: 'Classification with AI',
        sortable: false,
        value: 'classificationWithAI',
      },
      {
        text: 'Pra',
        sortable: false,
        value: 'pra',
      },
      {
        text: 'Shipment',
        sortable: false,
        value: 'shipment_id.name',
      },
      {
        text: 'Cad',
        sortable: false,
        value: 'cad',
      },
      {
        text: 'Notes',
        sortable: false,
        value: 'notes',
      },
      {
        text: 'Institution',
        sortable: true,
        value: 'institution_id',
      },
      {
        text: 'Name',
        sortable: false,
        value: 'user_id.name',
      },
      {
        text: 'Surname',
        sortable: false,
        value: 'user_id.surname',
      },
      // {
      //   text: 'Audio',
      //   sortable: false,
      //   value: '',
      // },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: '_id',
      descending: true,
    },
    parts: [],
    additionalParts: [],
    totalItems: 0,
    activeParts: [],
    counted: [],
    getCountedModal: false,
    loadingAll: false,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    role() {
      return this.$store.getters.role;
    },
    searchObject() {
      return this.$props.search;
    },
    computedParts() {
      const parts = [];
      this.parts.forEach((p) => {
        const part = p;
        this.activeParts.forEach((activePart) => {
          if (activePart.part === part._id) {
            part.active = true;
          }
        });
        parts.push(part);
      });
      return parts;
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getParts();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
      },
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getParts();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data);
        } else {
          console.log('search type is invalid');
        }
      },
    },
    parts(value) {
      this.$emit('items', value);
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('leko_employees.approve_parts.table.qr_code');
      this.headers[1].text = this.$i18n.t('leko_employees.approve_parts.table.actions');
      this.headers[2].text = this.$i18n.t('leko_employees.approve_parts.table.availability');
      this.headers[3].text = this.$i18n.t('leko_employees.approve_parts.table.sent_for_approval');
      this.headers[4].text = this.$i18n.t('leko_employees.approve_parts.table.oem');
      this.headers[5].text = this.$i18n.t('leko_employees.approve_parts.table.images');
      this.headers[6].text = this.$i18n.t('leko_employees.approve_parts.table.new');
      this.headers[7].text = this.$i18n.t('parts.table.classification_with_ai');
      this.headers[8].text = this.$i18n.t('leko_employees.approve_parts.table.pra');
      this.headers[9].text = this.$i18n.t('leko_employees.approve_parts.table.shipment');
      this.headers[10].text = this.$i18n.t('leko_employees.approve_parts.table.cad');
      this.headers[11].text = this.$i18n.t('leko_employees.approve_parts.table.notes');
      this.headers[12].text = this.$i18n.t('leko_employees.approve_parts.table.institution');
      this.headers[13].text = this.$i18n.t('leko_employees.approve_parts.table.name');
      this.headers[14].text = this.$i18n.t('leko_employees.approve_parts.table.surname');
      // this.headers[14].text = this.$i18n.t('leko_employees.approve_parts.table.audio');
    },
    getParts() {
      this.loading = true;
      Promise.all([
        getRequest(
          '/temporary-part/approve/datatable',
          `institution_id=${this.$store.getters.institutionId
          }&role=${this.$store.getters.role
          }&limit=${this.pagination.rowsPerPage
          }&page=${this.pagination.page
          }&sort_by=${this.pagination.sortBy
          }&descending=${this.pagination.descending}`,
        ),
        getRequest('/active-part'),
      ])
        .then((response) => {
          this.totalItems = response[0].count;
          this.parts = response[0].temporaryParts;
          this.activeParts = response[1]; // eslint-disable-line prefer-destructuring
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getAdditionalParts(kitId, expanded) {
      if (expanded && kitId) {
        this.loadingAdditional = true;
        getRequest('/temporary-part/additional', `kit_id=${kitId}`)
          .then((response) => {
            this.additionalParts = response.temporaryParts;
            this.loadingAdditional = false;
          })
          .catch((error) => {
            console.log(error);
            this.additionalParts = [];
            this.loadingAdditional = false;
          });
      } else {
        this.additionalParts = [];
      }
    },
    regularSearch(data) {
      this.loading = true;
      Promise.all([
        getRequest(
          '/temporary-part/approve/search/regular',
          `type=${data.searchType
          }&role=${this.$store.getters.role
          }&limit=${this.pagination.rowsPerPage
          }&page=${this.pagination.page
          }&sort_by=${this.pagination.sortBy
          }&descending=${this.pagination.descending
          }&search=${data.searchField}`,
        ),
        getRequest('/active-part'),
      ])
        .then((response) => {
          this.totalItems = response[0].count;
          this.parts = response[0].temporaryParts;
          this.activeParts = response[1]; // eslint-disable-line prefer-destructuring
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    advancedSearch(data) {
      this.loading = true;
      Promise.all([
        getRequest(
          '/temporary-part/approve/search/advanced',
          `type=${data.searchType
          }&institution=${data.institution
          }&role=${this.$store.getters.role
          }&limit=${this.pagination.rowsPerPage
          }&page=${this.pagination.page
          }&sort_by=${this.pagination.sortBy
          }&descending=${this.pagination.descending
          }&search=${data.searchField
          }&start_date=${data.startDate
          }&end_date=${data.endDate}`,
        ),
        getRequest('/active-part'),
      ])
        .then((response) => {
          this.totalItems = response[0].count;
          this.parts = response[0].temporaryParts;
          this.activeParts = response[1]; // eslint-disable-line prefer-destructuring
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    advancedSearchAdmin(data) {
      this.loadingAll = true;
      Promise.all([
        getRequest(
          '/temporary-part/approve/search-admin/superAdmin',
          `institutions=${data.institution
          }&role=${this.$store.getters.role
          }`,
        ),
      ])
        .then((response) => {
          if (response && response[0]) {
            this.counted = response[0];
            this.loadingAll = false;
            this.getCountedModal = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.loadingAll = false;
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    openQrDialog(id) {
      this.$emit('qr', id);
    },
    showImages(id, images, date) {
      this.$emit('images', {
        type: 'string', id, images, date,
      });
    },
    openActionsDialog(data) {
      this.$emit('actions', data);
    },
    updatePagination() {
      this.activeRow = null;
    },
    openInfoCarCad(data) {
      this.$emit('infocar-cad', data);
    },
    viewCar(carId) {
      this.$emit('view-car', { carId });
    },
  },
};
</script>
