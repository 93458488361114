<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >
          {{ $t('parts.insert_parts.form.edit_temporary_part') }}
          {{ temporaryPart._id.slice(temporaryPart._id.length-4, temporaryPart._id.length) }}
        </span>
        <span
          v-if="edit == false && additionalPart == false"
          class="headline"
        >{{ $t('parts.insert_parts.form.create_temporary_part') }}</span>
        <span
          v-if="edit == false && additionalPart == true"
          class="headline"
        >{{ $t('parts.insert_parts.form.create_additional_part') }}</span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!-------------------------------------SHIPMENT-------------------------------------->
              <v-flex
                xs12
                sm5
                md7
              >
                <!--SHIPMENT SELECTION-->
                <v-autocomplete
                  v-model="temporaryPart.shipment_id"
                  :items="shipments"
                  :rules="rules.required"
                  :label="$t('parts.insert_parts.form.shipment')"
                  color="red darken-1"
                  item-text="name"
                  item-value="_id"
                />
              </v-flex>
              <v-flex
                xs12
                sm7
                md5
              >
                <v-layout
                  align-center
                  justify-center
                  row
                  wrap
                >
                  <!--NEW SHIPMENT-->
                  <v-btn
                    color="success"
                    depressed
                    small
                    @click="newShipment"
                  >
                    {{ $t('parts.insert_parts.form.new_shipment') }}
                  </v-btn>
                  <!--SCAN SHIPMENT-->
                  <v-btn
                    color="primary"
                    depressed
                    small
                    @click="scanShipment"
                  >
                    {{ $t('parts.insert_parts.form.scan_shipment') }}
                  </v-btn>
                </v-layout>
              </v-flex>
              <!--------------------------------------NOTES---------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="temporaryPart.notes"
                  :label="$t('parts.insert_parts.form.notes')"
                  color="red darken-1"
                />
              </v-flex>
              <!----------------------------------INTERNAL NOTES----------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="temporaryPart.internal_notes"
                  :label="$t('parts.insert_parts.form.internal_notes')"
                  color="red darken-1"
                />
              </v-flex>
              <!------------------------------------LEKO NOTES------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="temporaryPart.leko_notes"
                  :label="$t('parts.insert_parts.form.leko_notes')"
                  color="red darken-1"
                />
              </v-flex>
              <!---------------------------------------BRAND--------------------------------------->
              <v-flex
                xs12
                sm6
              >
                <v-text-field
                  v-model="temporaryPart.brand"
                  :label="$t('parts.insert_parts.form.brand')"
                  color="red darken-1"
                />
              </v-flex>
              <!-------------------------------------OEM CODES------------------------------------->
              <v-flex
                xs12
                sm6
              >
                <v-text-field
                  v-model="oemCode"
                  :label="$t('parts.insert_parts.form.oem_code')"
                  color="red darken-1"
                  append-icon="add"
                  @keyup.enter="addOemCode()"
                  @click:append="addOemCode()"
                />
              </v-flex>
              <!--------------------------------------NR PRA--------------------------------------->
              <v-flex
                xs12
                sm6
              >
                <v-text-field
                  v-model="temporaryPart.pra"
                  :label="$t('parts.insert_parts.form.pra')"
                  color="red darken-1"
                />
              </v-flex>
              <!--------------------------------------CONDITION--------------------------------------->
              <v-flex
                xs6
                sm3
              >
                <div>
                  <div>{{ $t('parts.insert_parts.form.condition') }}</div>
                  <v-checkbox
                    v-model="temporaryPart.isNewPart"
                    :label="$t('parts.insert_parts.form.new')"
                    color="red darken-3"
                    hide-details
                    class="mt-0 pt-0"
                  />
                </div>
              </v-flex>
              <!--------------------------------------EXPORT EBAY--------------------------------------->
              <v-flex
                xs6
                sm3
              >
                <div>
                  <div>eBay</div>
                  <v-checkbox
                    v-model="temporaryPart.exportEbay"
                    :label="$t('parts.inventory.form.export')"
                    color="red darken-3"
                    hide-details
                    class="mt-0 pt-0"
                  />
                </div>
              </v-flex>
              <!--------------------------------INSERTED OEM CODES--------------------------------->
              <v-flex
                v-if="temporaryPart.oemCodes && temporaryPart.oemCodes.length > 0"
                xs12
                sm6
              >
                <div class="caption grey--text text--darken-1">
                  {{ $t('parts.insert_parts.form.oem_codes') }}
                </div>
                <v-chip
                  v-for="(item, index) in temporaryPart.oemCodes"
                  :key="index"
                >
                  {{ item }}
                  <v-icon
                    right
                    @click="removeOemCode(index)"
                  >
                    remove_circle
                  </v-icon>
                </v-chip>
              </v-flex>
              <!----------------------------------------------------------------------------------->
              <v-flex xs12>
                <v-layout
                  row
                  wrap
                >
                  <!-----------------------------------EBAY CODE----------------------------------->
                  <v-flex xs4>
                    <v-text-field
                      v-model="temporaryPart.ebayCode"
                      :label="$t('parts.insert_parts.form.ebay_code')"
                      color="red darken-1"
                    />
                  </v-flex>
                  <!------------------------------------PRICE-------------------------------------->
                  <v-flex xs4>
                    <v-text-field
                      v-model="temporaryPart.price"
                      :label="$t('parts.insert_parts.form.price')"
                      color="red darken-1"
                      type="number"
                      step="0.01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!--
                  <v-flex xs4>
                    <v-text-field
                      v-model="temporaryPart.cost"
                      :label="$t('parts.insert_parts.form.cost')"
                      color="red darken-1"
                      type="number"
                      step="0.01"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                -->
                  <v-flex xs4>
                    <v-select
                      v-model="selectedCurrency"
                      :items="currency"
                      :label="$t('parts.insert_parts.form.currency')"
                      color="red darken-1"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!--------------------------------------WEIGHT--------------------------------------->
              <v-flex xs8>
                <v-text-field
                  v-model="weight.value"
                  :label="$t('parts.insert_parts.form.weight')"
                  color="red darken-1"
                  type="number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="weight.unit"
                  :items="weightUnits"
                  :label="$t('parts.insert_parts.form.unit')"
                  color="red darken-1"
                />
              </v-flex>
              <!--------------------------------------QUALITY-------------------------------------->
              <v-flex xs12>
                <v-select
                  v-model="temporaryPart.quality"
                  :label="$t('parts.insert_parts.form.quality')"
                  :items="qualities"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                >
                  <template v-slot:item="{ item }">
                    <v-chip>
                      <v-rating
                        v-model="item.rating"
                        color="red"
                        background-color="red"
                        readonly
                      />
                    </v-chip>
                  </template>

                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <v-rating
                        v-model="item.rating"
                        small
                        readonly
                        color="red"
                        background-color="red"
                      />
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>
              <!-------------------------------------WARRANTY-------------------------------------->
              <v-flex xs12>
                <v-select
                  v-model="temporaryPart.warranty_id"
                  :items="warranties"
                  :label="$t('parts.insert_parts.form.warranty')"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                >
                  <template v-slot:item="{ item }">
                    {{ item.return_days / 86400 }} {{ $t('warranties.days') }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ item.return_days / 86400 }} {{ $t('warranties.days') }}
                  </template>
                </v-select>
              </v-flex>
              <!------------------------------CLASSIFICATION WITH AI------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="temporaryPart.classificationWithAI"
                  :label="$t('parts.insert_parts.form.classification_with_ai')"
                  color="red darken-1"
                />
              </v-flex>
              <!------------------------------------REPOSITORY------------------------------------->
              <v-flex xs12>
                <repository
                  ref="repository"
                  @location="setPartLocation"
                  @hide="hideEditDialog"
                />
              </v-flex>

              <!----------------------------------------KIT---------------------------------------->
              <v-flex
                v-if="!additionalPart"
                xs12
              >
                <v-layout
                  align-center
                  justify-center
                  row
                  wrap
                >
                  <!--Kit-->
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="temporaryPart.kit"
                      :label="$t('parts.insert_parts.form.kit')"
                      color="red darken-1"
                    />
                  </v-flex>
                  <!--Main Part-->
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="temporaryPart.kit_main_part"
                      :disabled="!temporaryPart.kit"
                      :label="$t('parts.insert_parts.form.main_part')"
                      color="primary"
                    />
                  </v-flex>
                  <!--Price-->
                  <v-flex
                    v-if="temporaryPart.kit && temporaryPart.kit_main_part"
                    xs12
                    sm3
                  >
                    <v-text-field
                      v-model="temporaryPart.kit_price"
                      :label="$t('parts.insert_parts.form.kit_price')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!--Scan Main Part-->
                  <v-flex
                    v-if="temporaryPart.kit && !temporaryPart.kit_main_part"
                    xs6
                    sm3
                  >
                    <v-btn
                      :color="temporaryPart.kit_id ? 'green' : 'orange'"
                      outline
                      @click="$emit('scan-main-part')"
                    >
                      {{ $t('parts.insert_parts.form.scan_main_part') }}
                      <v-icon right>
                        {{ temporaryPart.kit_id && temporaryPart.kit_id !== null
                          ? 'done_outline'
                          : 'warning' }}
                      </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!--------------------------------------WEBCAM--------------------------------------->
              <v-flex xs12>
                <camera
                  v-if="dialog"
                  @takePicture="takePicture"
                />
              </v-flex>
              <!--------------------------------------IMAGES--------------------------------------->
              <v-flex xs12>
                <v-container
                  grid-list-sm
                  fluid
                >
                  <v-layout
                    row
                    wrap
                  >
                    <!--NEW PHOTOS-->
                    <v-flex
                      v-for="(data, index) in tempImages"
                      :key="index"
                      xs6
                      sm4
                      md3
                      lg2
                      xl1
                      d-flex
                    >
                      <v-hover>
                        <v-card
                          slot-scope="{ hover }"
                          flat
                          tile
                          class="d-flex"
                        >
                          <v-img :src="data.dataUrl">
                            <v-layout
                              row
                              ma-1
                            >
                              <v-icon
                                v-if="hover"
                                x-large
                                color="primary"
                                class="pointer"
                                @click="viewImage('dataUrl', data.dataUrl)"
                              >
                                image
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="orange"
                                class="pointer"
                                @click="openImageEditor('dataUrl', index, data.dataUrl)"
                              >
                                edit
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="red"
                                class="pointer"
                                @click="deleteTempImage(index)"
                              >
                                delete_forever
                              </v-icon>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-flex>
                    <!--EXISTING PHOTOS-->
                    <v-flex
                      v-for="(image, index) in temporaryPart.images"
                      :key="`t${index}`"
                      xs6
                      sm4
                      md3
                      lg2
                      xl1
                      d-flex
                    >
                      <v-hover>
                        <v-card
                          slot-scope="{ hover }"
                          flat
                          tile
                          class="d-flex"
                          draggable="true"
                          :data-index="index"
                          @dragstart="dragStartHandler"
                          @dragover.prevent
                          @drop="dropHandler"
                          @dragend="dragEndHandler"
                        >
                          <v-img
                            :src="`${server}/images/?q=${image.thumb}`"
                          >
                            <v-layout
                              row
                              ma-1
                            >
                              <v-icon
                                v-if="hover"
                                x-large
                                color="primary"
                                class="pointer"
                                @click="viewImage('string', image)"
                              >
                                image
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="orange"
                                class="pointer"
                                @click="openImageEditor('string', index, image)"
                              >
                                edit
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="red"
                                class="pointer"
                                @click="deleteImage(index)"
                              >
                                delete_forever
                              </v-icon>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-flex>
                  </v-layout>
                </v-container>

                <!-- <img-grid
                  :images="images"
                  @imagesChanged="updateImages"
                  @open="openImgDialog"
                  @delete="deleteImage"
                /> -->
              </v-flex>
              <!----------------------------------AUDIO RECORDER----------------------------------->
              <!-- <v-flex xs12>
                <audio-recorder
                  v-if="dialog"
                  @record="record"
                  @delete="deleteRecording"
                />
              </v-flex> -->
              <!----------------------------------------------------------------------------------->
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.insert_parts.form.close') }}
        </v-btn>
        <v-btn
          :disabled="disabled"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('parts.insert_parts.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Repository from '@/components/InsertParts/repository.vue';
import Camera from '@/components/camera.vue';
import AudioRecorder from '@/components/audioRecorder.vue';

import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';

export default {
  components: {
    repository: Repository,
    camera: Camera,
    'audio-recorder': AudioRecorder,
  },
  props: ['hide'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    dialog: false,
    edit: false,
    valid: false,
    disabled: false,
    loading: false,
    additionalPart: false,
    showRepo: false,
    oemCode: null,
    currency: ['EUR'], // 'ALL'
    selectedCurrency: 'EUR',
    weight: { value: 0, unit: 'kg' },
    weightUnits: ['kg'],
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      required: [v => !!v || 'Please fill this field'],
    },
    temporaryPart: {},
    shipments: [],
    qualities: [],
    warranties: [],
    tempImages: [],
    recording: {},
    draggingIndex: -1,
    dropIndex: -1,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    hideDialog() {
      return this.$props.hide;
    },
  },
  watch: {
    hideDialog(value) {
      if (value) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    },
    temporaryPart: {
      handler(value) {
        if (value.kit === false) {
          this.temporaryPart.kit_main_part = false;
        }
        // Disable save button.
        if (value.kit === true && value.kit_main_part === false && !value.kit_id) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    open(edit, additional, data) {
      this.getShipments();
      this.getQualities();
      this.getWarranties();
      this.openRepository(data);
      this.temporaryPart = data;
      if (data.weight) this.weight = data.weight;
      this.edit = edit;
      this.additionalPart = additional;
      this.dialog = true;
      this.part = data;
    },
    close() {
      this.clearPartLocation();
      this.temporaryPart = {};
      this.shipments = [];
      this.qualities = [];
      this.warranties = [];
      this.tempImages = [];
      this.recording = {};
      this.edit = false;
      this.additionalPart = false;
      this.weight = { value: 0, unit: 'kg' };
      this.loading = false;
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      this.loading = true;
      const formData = new FormData();

      this.temporaryPart.currency = this.selectedCurrency;
      this.temporaryPart.weight = this.weight;

      if (this.temporaryPart.isEmpty) this.temporaryPart.initialSave = true;
      else this.temporaryPart.initialSave = false;

      this.temporaryPart.currentUser = this.$store.getters.userId;
      this.temporaryPart.isEmpty = false;

      // APPEND PHOTOS
      if (this.tempImages.length > 0) {
        this.tempImages.forEach((image) => {
          formData.append('photos', image.blob, image.title);
        });
      }

      formData.append(
        'data',
        JSON.stringify({
          part: this.temporaryPart,
          user: this.$store.getters.userId,
        }),
      );

      if (Object.keys(this.recording).length > 0 && this.recording.blob) {
        formData.append('audio', this.recording.blob, 'record.ogg');
      }

      postRequest
        .formData(
          '/temporary-part/insert/',
          `id=${this.temporaryPart._id}`,
          formData,
        )
        .then((response) => {
          this.$emit('save');
          this.close();
        })
        .catch((error) => {
          console.log(error.response);
          this.close();
        });
    },
    getShipments() {
      getRequest(
        '/shipment',
        `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => { this.shipments = response.shipments; })
        .catch((error) => { console.log(error); });
    },
    getQualities() {
      getRequest('/quality/')
        .then((response) => { this.qualities = response.qualities; })
        .catch((error) => { console.log(error); });
    },
    getWarranties() {
      getRequest('/warranty')
        .then((response) => { this.warranties = response.warranties; })
        .catch((error) => { console.log(error); });
    },
    takePicture(data) {
      const randomString = Math.random().toString(36).substring(9);
      const imgObj = {
        title: `${randomString}-photo${this.tempImages.length}.png`,
        dataUrl: data.dataUrl,
        blob: data.blob,
      };
      this.tempImages.push(imgObj);
    },
    record(data) {
      this.recording = data;
      this.temporaryPart.audio = { exists: true, title: 'record.ogg' };
    },
    deleteRecording() {
      this.recording = {};
      this.temporaryPart.audio = { exists: false, title: null };
    },
    viewImage(type, data) {
      this.$emit('open-img-dialog', { type, data });
    },
    deleteImage(index) {
      this.temporaryPart.images.splice(index, 1);
    },
    deleteTempImage(index) {
      this.tempImages.splice(index, 1);
    },
    openImageEditor(type, index, data) {
      this.$emit('open-img-editor', { type, index, data });
    },
    setEditedImage(image) {
      let type = '';
      // Remove the existing unedited image.
      if (image.type === 'dataUrl') { type = 'n'; this.deleteTempImage(image.index); }
      if (image.type === 'string') { type = 'e'; this.deleteImage(image.index); }
      // Add the edited one.
      this.tempImages.push({ title: `${type}-${image.index}-${image.title}`, dataUrl: image.dataUrl, blob: image.blob });
    },
    openRepository(data) {
      const { repository } = this.$refs;
      repository.open(data);
    },
    setPartLocation(data) {
      this.temporaryPart.repository_id = data.repository_id;
      this.temporaryPart.room_id = data.room_id;
      this.temporaryPart.location_id = data.location_id;
      this.temporaryPart.shelf_id = data.shelf_id;
    },
    clearPartLocation() {
      const { repository } = this.$refs;
      repository.clear();
    },
    newShipment() {
      this.$emit('new-shipment');
    },
    scanShipment() {
      this.$emit('scan-shipment');
    },
    setShipment(shipmentId) {
      this.temporaryPart.shipment_id = shipmentId;
    },
    hideEditDialog(value) {
      if (value) this.dialog = false;
      else this.dialog = true;
    },
    setMainPart(data) {
      this.temporaryPart.kit_id = data;
      console.log(this.temporaryPart);
    },
    addOemCode() {
      this.temporaryPart.oemCodes.push(this.oemCode);
      // set the first oemcode as ebay code
      [this.temporaryPart.ebayCode] = this.temporaryPart.oemCodes;
      this.oemCode = null;
    },
    removeOemCode(index) { this.temporaryPart.oemCodes.splice(index, 1); },
    dragStartHandler(e) {
      this.draggingIndex = parseInt(e.currentTarget.getAttribute('data-index'), 10);
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', null);
    },
    dropHandler(e) {
      this.dropIndex = parseInt(e.currentTarget.getAttribute('data-index'), 10);
      if (this.draggingIndex !== this.dropIndex) {
        const draggedImage = this.part.images[this.draggingIndex];
        this.part.images.splice(this.draggingIndex, 1);
        this.part.images.splice(this.dropIndex, 0, draggedImage);
      }
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },
    dragEndHandler() {
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },
  },
};
</script>
