<template>
  <v-container
    fluid
    class="pa-0 col-12 justify-center"
  >
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('lekoAI.subtitle2') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>

    <v-layout
      row
      class="pa-3"
    >
      <v-flex
        xs6
        class="mr-4 align-center pt-5"
      >
        <v-img
          v-if="imageUrl || image.src"
          :src="imageUrl || image.src"
          alt="Uploaded Image"
          max-height="300"
          contain
        />
        <div
          v-else
          class="default-image-text"
        >
          Test image will be shown here
        </div>
      </v-flex>

      <v-flex
        xs6
        class="ml-4 mt-4"
      >
        <h2 class="mt-4">
          Upload Image
        </h2>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="handleFileUpload"
        >
        <br><br>

        OR
        <v-form class="mt-2">
          <v-text-field
            v-model="imageUrl"
            class="mt-2"
            label="Enter Image URL"
            outlined
          />

          <v-btn
            color="primary"
            class="mt-4"
            :loading="loading"
            :disabled="!imageUrl && !image.src"
            @click="getImageDetails"
          >
            Classify Image
          </v-btn>
          <div
            v-if="predictions.length > 0"
            class="mt-4"
          >
            <h3 class="mb-2 mt-2">
              Top 3 Predictions:
            </h3>
            <ul>
              <li
                v-for="(prediction, index) in predictions.slice(0, 3)"
                :key="index"
              >
                <strong>{{ prediction.tagName }}</strong>:
                {{ (prediction.probability * 100).toFixed(2) }}%
              </li>
            </ul>
          </div>
        </v-form>
      </v-flex>
    </v-layout>
    <div class="pa-3">
      <v-flex xs12>
        <div class="response-card">
          <div class="d-flex col-12">
            <h2 class="col-6">
              Response
            </h2>
            <!-- <button
              class=" col-6 text-end copy-button ml-auto"
              @click="copyToClipboard"
            >
              <v-icon>{{ copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
            </button> -->
          </div>
          <div class="col-12 d-flex text-end justify-end">
            {{ copied ? 'Copied!' : '' }}
          </div>
          <p v-if="partDetails">
            Based on the image, here's what I can tell you about the part:
          </p>
          <p v-else>
            Image details will be shown here
          </p>
          <div
            v-if="partDetails"
            v-html="partDetails"
          />
        </div>
      </v-flex>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';

import { dataURLtoBlob } from '../utils/files';

export default {
  data() {
    return {
      image: {
        type: null, // 'string' | 'file'
        src: null,
        url: null,
      },
      loading: false,
      predictions: [],
      copied: false,
      partDetails: null,
      imageUrl: '',
      imageValid: false,
    };
  },
  watch: {
  },
  methods: {
    handleFileUpload() {
      // Get the file input element using the ref
      const { fileInput } = this.$refs;

      if (fileInput.files.length > 0) {
        const image = fileInput.files[0];
        const reader = new FileReader();
        reader.onload = (event) => { this.image = { type: 'file', src: event.target.result, url: null }; };
        reader.readAsDataURL(image);
      }
    },
    copyToClipboard() {
      const text = `Type: ${this.partDetails.type}\nOEM Number: ${this.partDetails.oemNumber}\nBrand: ${this.partDetails.brand}\nSources: ${this.partDetails.sources.map(s => s.url).join(', ')}\nSearch Suggestions: ${this.partDetails.searchSuggestions.join(', ')}`;

      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        setTimeout(() => (this.copied = false), 2000);
      }).catch(err => console.error('Failed to copy:', err));
    },
    // https://api.lekotech.com/images?q=parts/2025/02/19574ff5e7d3f1e25c0c26cf98619786c45e39b187e.jpeg
    async getImageDetails() {
      if (!this.imageUrl && !this.image.src) {
        console.error('Invalid image.');
        return;
      }

      this.loading = true;

      try {
        let formData;
        const config = {};

        if (this.image.type === 'file') {
          // Send file as FormData
          formData = new FormData();
          formData.append('image', dataURLtoBlob(this.image.src), 'uploaded-image.jpg');
          config.headers = { 'Content-Type': 'multipart/form-data' };
        } else {
          // Send URL as JSON
          formData = { image: this.imageUrl };
        }

        const res = await axios.post(
          'https://oemlabeldetectionv4-97815853843.us-central1.run.app/get-image-detail',
          formData,
          config,
        );

        this.partDetails = res.data.data.replace(/\n/g, '<br>');
      } catch (err) {
        console.error('Error fetching image details:', err);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>

  <style scoped>
  .default-image-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: #ccc;
    color: #555;
    font-size: 18px;
    font-weight: bold;
  }
  .response-card {
  background-color: #e9eef6;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
h3 {
  font-size: 16px;
  margin-top: 12px;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  margin: 5px 0;
}
.search-suggestions {
  background-color: #fff;
  padding: 2px;
  border-radius: 10px;
}
.grounding-sources {
  background-color: #fff;
  padding: 2px;
  border-radius: 10px;
}
.grounding-sources span {
  cursor: help;
}
.search-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.tag {
  background: #eee;
  padding: 5px 10px;
  border-radius: 5px;
}
.copy-button {
  display: contents !important;
}
  </style>
