/* eslint-disable no-undef */
<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-center
      >
        <!--TITLE-->
        <v-toolbar-title class="hidden-sm-and-down">
          {{ $t('parts.inventory.label') }}
        </v-toolbar-title>
        <v-divider
          class="mx-5 hidden-sm-and-down"
          inset
          vertical
        />
        <v-spacer class="hidden-sm-and-down" />

        <v-btn
          v-if="searchType === 'title' || searchType === 'multipleTitles'"
          icon
          class="mr-3"
          @click="openSearchList"
        >
          <v-icon>list</v-icon>
        </v-btn>

        <!--SEARCH BY TITLE-->
        <v-autocomplete
          v-if="searchType === 'title'"
          v-model="titleCode"
          :loading="loadingTitle"
          :items="titles"
          :search-input.sync="searchTitle"
          :label="$t('parts.inventory.search')"
          :filter="() => true"
          :item-text="`title.${$store.getters.language}`"
          item-value="partCode"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              class="listRowColor"
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">Info Car</span>
                  <span
                    class="body-2"
                    v-html="highlight(item.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
              <v-flex
                v-for="(element, index) in item.related"
                :key="index"
                class="hidden-sm-and-down"
              >
                <v-layout
                  column
                  wrap
                >
                  <span
                    class="body-1"
                    style="margin-top: 17px;"
                    v-html="highlight(element.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>

        <!--SEARCH BY MULTIPLE TITLES-->
        <v-autocomplete
          v-else-if="searchType === 'multipleTitles'"
          v-model="titleCodes"
          :loading="loadingMultipleTitles"
          :items="titleGroups"
          :search-input.sync="searchMultipleTitles"
          :label="$t('parts.movements.search')"
          :item-text="`title.${$store.getters.language}`"
          item-value="codes"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />

        <!--SEARCH OTHER FIELDS-->
        <v-text-field
          v-else
          v-model="search"
          :label="
            searchType === 'other'
              ? $t('parts.inventory.search_other')
              : $t('parts.inventory.search')
          "
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />
        <!--SEARCH TYPE-->
        <v-flex
          sm2
          lg1
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
            class="ml-1"
          />
        </v-flex>

        <!--ADVANCED-->
        <v-btn
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.inventory.advanced') }}
        </v-btn>

        <v-spacer class="hidden-md-and-down" />
      </v-layout>
    </v-toolbar>
    <div class="redColor">
      <!--------------------------------------ACTION BUTTONS--------------------------------------->
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex :grow="$vuetify.breakpoint.mdAndUp">
          <v-layout
            row
            wrap
            align-center
            :justify-center="$vuetify.breakpoint.smAndDown"
          >
            <!-- PDF -->
            <!-- <v-btn
              :loading="loadingPdf"
              :disabled="!$store.getters.institutionEmail
                || $store.getters.institutionEmail.length <= 1
              "
              color="grey darken-4"
              dark
              @click="exportPdf"
            >
              {{ $t('parts.inventory.export') }}
              <v-icon right>
                fas fa-file-pdf
              </v-icon>
            </v-btn> -->
            <v-btn
              dark
              color="grey darken-4"
            >
              {{ $t('parts.inventory.export') }}
              <v-icon right>
                fas fa-file-pdf
              </v-icon>
            </v-btn>
            <!--Sync. Price-->
            <v-btn
              color="grey darken-4"
              dark
              :loading="loadingSyncPrice"
              @click="syncPrice"
            >
              {{ $t('parts.inventory.sync_price') }}
              <v-icon right>
                sync
              </v-icon>
            </v-btn>

            <!--EXCEL-->
            <download-excel
              v-if="selectedItem"
              :fetch="exportData"
              type="xls"
              name="Inventory"
            >
              <v-btn
                color="grey darken-4"
                dark
              >
                {{ $t('parts.inventory.export') }}
                <v-icon right>
                  fas fa-file-excel
                </v-icon>
              </v-btn>
            </download-excel>
            <!--EXCEL-->
            <download-excel
              v-if="selectedItem && $store.getters.institutionExportEbayLeko"
              :fetch="exportDataEbay"
              type="csv"
              :escape-csv="false"
              name="exportEbay.csv"
              worksheet="Worksheet"
            >
              <v-btn
                color="grey darken-4"
                dark
              >
                {{ $t('parts.inventory.export_ebay') }}
                <v-icon right>
                  fas fa-file-excel
                </v-icon>
              </v-btn>
            </download-excel>
            <!--Archive-->
            <v-btn
              v-if="selectedItem"
              :loading="loadingArchive"
              color="grey darken-4"
              dark
              @click="exportArchive"
            >
              {{ $t('parts.inventory.export') }}
              <v-icon right>
                fas fa-file-archive
              </v-icon>
            </v-btn>

            <!--Share-->
            <v-btn
              v-if="selectedItem"
              color="grey darken-4"
              dark
              @click="share"
            >
              {{ $t('parts.inventory.share') }}
              <v-icon right>
                share
              </v-icon>
            </v-btn>

            <!--Delete-->
            <v-btn
              v-if="selectedItem && ($store.getters.role == 'LekoAdmin' || $store.getters.role == 'SuperAdmin') "
              color="grey darken-4"
              dark
              :loading="loadingDeletePart"
              @click="deleteParts"
            >
              {{ $t('parts.inventory.delete') }}
              <v-icon right>
                delete
              </v-icon>
            </v-btn>
            <v-btn
              v-if="$store.getters.institutionExportEbayLeko"
              color="grey darken-4"
              dark
              @click="syncEbay"
            >
              {{ $t('parts.inventory.sync_with_ebay') }}
              <v-icon right>
                sync
              </v-icon>
            </v-btn>
            <v-btn
              v-if="selectedItem && $store.getters.institutionExportEbayLeko"
              color="grey darken-4"
              dark
              @click="addEbayItems"
            >
              {{ $t('parts.inventory.add_ebay') }}
              <i class="fa fa-list-ol ml-2 mr-1" />
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              v-if="selectedItem && checkEbayStatus(items) && $store.getters.institutionExportEbayLeko"
              color="grey darken-4"
              dark
              @click="deleteEbayItems"
            >
              {{ $t('parts.inventory.delete_ebay') }}
              <i class="fa fa-list-ol ml-2 mr-1" />
              <v-icon right>
                delete
              </v-icon>
            </v-btn>
            <v-btn
              v-if="selectedItem && $store.getters.institutionExportGoogleLeko"
              color="grey darken-4"
              dark
              @click="addGoogleItems"
            >
              {{ $t('parts.inventory.add_google') }}
              <i class="fa fa-list-ol ml-2 mr-1" />
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              v-if="selectedItem && checkGoogleStatus(items) && $store.getters.institutionExportEbayLeko"
              color="grey darken-4"
              dark
              @click="deleteGoogleItems"
            >
              {{ $t('parts.inventory.delete_google') }}
              <i class="fa fa-list-ol ml-2 mr-1" />
              <v-icon right>
                delete
              </v-icon>
            </v-btn>
            <!--No Applicability-->
            <v-btn
              v-if="advancedSearch"
              color="grey darken-4"
              dark
              @click="toggleNoApplicabilityDropdown"
            >
              No Appl.
              <v-icon right>
                {{ noApplicabilityDropdown ? 'check_box' : 'check_box_outline_blank' }}
              </v-icon>
            </v-btn>
            <!--InfoCar Cad-->
            <v-btn
              v-if="advancedSearch
                && $store.getters.institutionDisplayFeatures.infoCarCad
                && brand && model && version"
              color="grey darken-4"
              dark
              @click="openInfoCarCad"
            >
              {{ $t('parts.inventory.cad') }}
              <v-icon right>
                info
              </v-icon>
            </v-btn>
            <!--Car Parts List-->
            <v-btn
              v-if="advancedSearch && brand && model && version"
              color="grey darken-4"
              dark
              @click="openCarPartsList"
            >
              {{ $t('parts.inventory.car_parts_list') }}
              <v-icon right>
                directions_car
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>

        <v-flex :shrink="$vuetify.breakpoint.mdAndUp">
          <v-layout
            row
            justify-center
          >
            <!--SCAN QR-->
            <v-btn
              class="ml-4"
              color="grey darken-4"
              dark
              @click="scanQr"
            >
              <v-icon left>
                fas fa-qrcode
              </v-icon>
              {{ $t('parts.inventory.scan_qr') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <!--------------------------------------ADVANCED SEARCH-------------------------------------->
      <div v-if="advancedSearch">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-layout
            row
            color="red darken-3"
            class="pb-2"
            align-center
            wrap
          >
            <!--NO APPLICABILITY DROPDOWN-->
            <v-flex
              v-if="advancedSearch && noApplicabilityDropdown"
              xs12
            >
              <v-layout
                row
                ma-2
              >
                <v-flex
                  xs12
                  sm2
                >
                  <v-checkbox
                    v-model="advancedSearchItems.noApplicability"
                    :label="$t('parts.advanced_search.no_applicability')"
                    dark
                    hide-details
                    color="grey lighten-4"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm2
                >
                  <v-checkbox
                    v-model="advancedSearchItems.noNotes"
                    :label="$t('parts.advanced_search.no_notes')"
                    dark
                    hide-details
                    color="grey lighten-4"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!---------------------------------------DIVIDER--------------------------------------->
            <v-flex xs12>
              <v-layout
                class="mt-2 mb-2"
                row
                wrap
              >
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
                <div class="ml-4 mr-4 title white--text">
                  {{ $t('parts.advanced_search.car_details') }}
                </div>
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
              </v-layout>
            </v-flex>
            <!----------------------------------------PLATE---------------------------------------->
            <v-flex
              xs12
              sm3
              lg4
            >
              <v-text-field
                v-model="plate"
                flat
                solo
                return-masked-value
                mask="AA###AA"
                :disabled="!canSearchPlates"
                :color="foundPlate ? 'success': 'error'"
                :label="$t('parts.advanced_search.plate') + ' ' + examplePlateFormat"
                :loading="loadingPlate"
                class="ml-2 mr-2 mt-2"
                hide-details
                :append-icon="foundPlate ? 'check_circle': 'error'"
                @keyup.enter="searchPlate()"
                @click:append="searchPlate()"
              />
            </v-flex>
            <!----------------------------------------BRAND---------------------------------------->
            <v-flex
              xs12
              sm7
              lg4
            >
              <v-autocomplete
                v-model="brand"
                :items="brands"
                :loading="loadingBrand"
                :label="$t('parts.advanced_search.brand')"
                item-text="title"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="brand === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="brand = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs3
                      sm2
                      lg1
                    >
                      <v-img
                        :src="item.logo"
                        max-height="35"
                        max-width="35"
                      />
                    </v-flex>
                    <v-flex
                      xs9
                      sm10
                      lg11
                    >
                      <span> {{ item.title }} </span>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs3
                      lg2
                    >
                      <v-img
                        :src="item.logo"
                        max-height="35"
                        max-width="35"
                      />
                    </v-flex>
                    <v-flex
                      xs9
                      lg10
                    >
                      <span> {{ item.title }} </span>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!----------------------------------------YEAR----------------------------------------->
            <v-flex
              xs12
              sm2
              lg4
            >
              <v-text-field
                v-model="year"
                :label="$t('parts.advanced_search.year')"
                :rules="[yearRule]"
                flat
                solo
                type="number"
                :max="new Date().getFullYear()"
                oninput="validity.valid||(value=new Date().getFullYear());"
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
              />
            </v-flex>
            <!----------------------------------------MODEL---------------------------------------->
            <v-flex
              xs12
              sm8
              xl4
            >
              <v-autocomplete
                v-model="model"
                :items="models"
                :loading="loadingModel"
                :label="$t('parts.advanced_search.model')"
                item-text="fullDescription"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="model === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="model = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md7
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                        <span class="body-2">
                          {{ item.description }}
                        </span>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      class="hidden-sm-and-down"
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md7
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                        <span class="body-2">
                          {{ item.description }}
                        </span>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      class="hidden-sm-and-down"
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!---------------------------------------ENGINE---------------------------------------->
            <v-flex
              xs12
              sm4
              xl2
            >
              <!--------------------------------------SUPPLY--------------------------------------->
              <v-layout
                column
                class="ml-2 mt-2"
              >
                <div class="subheading white--text">
                  {{ $t('parts.advanced_search.supply.supply') }}
                </div>
                <v-radio-group
                  v-model="supply"
                  class="ma-0"
                  dark
                  row
                  hide-details
                >
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[0].title"
                    :value="supplies[0].code"
                  />
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[1].title"
                    :value="supplies[1].code"
                  />
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[2].title"
                    :value="supplies[2].code"
                  />
                </v-radio-group>
              </v-layout>

              <v-autocomplete
                v-model="engine"
                :items="versions"
                :loading="loadingVersion"
                :label="$t('parts.advanced_search.engine')"
                item-text="engineCode"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                :append-icon="engine === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="
                  engine = null;
                  version = null;
                  if (model) getVersions(model.brandCode, model.modelCode);
                "
              />
            </v-flex>
            <!---------------------------------------VERSION--------------------------------------->
            <v-flex
              xs12
              xl6
            >
              <v-autocomplete
                v-model="version"
                :items="versions"
                :loading="loadingVersion"
                :label="$t('parts.advanced_search.version')"
                item-text="fullDescription"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="version === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="version = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                        <span class="body-2">{{ item.description }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      class="hidden-sm-and-down"
                      md2
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                        <span class="body-2">{{ item.numberOfDoors }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                        <span class="body-2">
                          {{ item.power.kw }} kw,
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                        <span class="body-2">{{ item.engineCode }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                        <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>

                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                        <span class="body-2">{{ item.description }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                        <span class="body-2">{{ item.numberOfDoors }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                        <span class="body-2">
                          {{ item.power.kw }} kw,
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                        <span class="body-2">{{ item.engineCode }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                        <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!---------------------------------------DIVIDER--------------------------------------->
            <v-flex xs12>
              <v-layout
                class="mt-2 mb-3"
                row
                wrap
              >
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
                <div class="ml-4 mr-4 title white--text">
                  {{ $t('parts.advanced_search.part_details') }}
                </div>
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
              </v-layout>
            </v-flex>
            <!------------------------------------INSTITUTIONS------------------------------------->
            <v-flex
              v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
              xs12
              :sm6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
              :xl2="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
            >
              <v-autocomplete
                v-model="advancedSearchItems.institution"
                :items="institutions"
                :loading="loadingInstitution"
                :label="$t('parts.advanced_search.institution')"
                :hint="$t('parts.advanced_search.institution')"
                persistent-hint
                item-text="title"
                item-value="_id"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2"
              />
            </v-flex>
            <!--------------------------------------SHIPMENTS-------------------------------------->

            <v-flex
              xs12
              :sm6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
              :lg4="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
              :xl2="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
              :xl4="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
            >
              <v-autocomplete
                v-model="advancedSearchItems.shipment"
                :items="shipments"
                :loading="loadingShipment"
                :label="$t('parts.advanced_search.shipment')"
                :hint="$t('parts.advanced_search.shipment')"
                persistent-hint
                item-text="name"
                item-value="_id"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2"
              />
            </v-flex>
            <!----------------------------------------PRICE---------------------------------------->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
              xl2
            >
              <v-combobox
                v-model="advancedSearchItems.price[0]"
                :items="prices"
                flat
                solo
                :label="this.$i18n.t('parts.inventory.from')"
                :hint="$t('parts.inventory.from')"
                persistent-hint
                color="red darken-1"
                class="ml-2 mr-2"
                prepend-icon="euro"
                type="number"
                oninput="validity.valid||(value='');"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
              xl2
            >
              <v-combobox
                v-model="advancedSearchItems.price[1]"
                :items="prices"
                solo
                :label="this.$i18n.t('parts.inventory.upto')"
                :hint="$t('parts.inventory.upto')"
                persistent-hint
                flat
                color="red darken-1"
                class="ml-2 mr-2"
                prepend-icon="euro"
                type="number"
                oninput="validity.valid||(value='');"
              />
            </v-flex>
            <!----------------------------------------DATE----------------------------------------->
            <!--STARTING DATE-->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
              xl2
            >
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="advancedSearchItems.startDate"
                    :label="$t('parts.advanced_search.starting_date')"
                    :hint="$t('parts.advanced_search.select_starting_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="advancedSearchItems.startDate"
                  color="red darken-3"
                  @input="startDateMenu = false"
                />
              </v-menu>
            </v-flex>
            <!--ENDING DATE-->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')"
              xl2
            >
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="advancedSearchItems.endDate"
                    :label="$t('parts.advanced_search.ending_date')"
                    :hint="$t('parts.advanced_search.select_ending_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="advancedSearchItems.endDate"
                  color="red darken-3"
                  @input="endDateMenu = false"
                />
              </v-menu>
            </v-flex>

            <!-- CONDITIONAL CHECKBOXES -->
            <v-flex
              v-if="searchType != 'id'"
              xs12
              lg4
              md4
              xl4
            >
              <v-layout
                column
                class="ml-2 mt-2"
              >
                <div class="subheading white--text">
                  {{ $t('parts.advanced_search.select_position') }}
                </div>
                <v-radio-group
                  v-model="advancedSearchItems.positionCode"
                  row
                  hide-details
                >
                  <v-radio
                    color="grey lighten-4"
                    :label="$t('parts.advanced_search.select_all')"
                    value=""
                  />
                  <v-radio
                    color="grey lighten-4"
                    label="Neutro"
                    value="S000"
                  />
                  <v-radio
                    color="grey lighten-4"
                    label="SX"
                    value="S001"
                  />
                  <v-radio
                    color="grey lighten-4"
                    label="DX"
                    value="S002"
                  />
                </v-radio-group>
              </v-layout>
            </v-flex>
            <!-- SEARCH BY EBAY STATUS -->
            <v-flex
              xs12
              lg4
              md4
              xl4
            >
              <v-layout
                column
                class="ml-2 mt-2"
              >
                <div class="subheading white--text">
                  {{ $t('parts.advanced_search.filter_for') }} <strong>{{ $t('parts.advanced_search.ebay_status') }}</strong>
                </div>
                <div class="d-flex">
                  <v-radio-group
                    v-model="advancedSearchItems.eBayStatus"
                    row
                    hide-details
                    ma-0
                    class="mr-0 pr-0"
                    style="width: 15%;"
                  >
                    <v-radio
                      :disabled="!$store.getters.institutionExportEbayLeko && $store.getters.role != 'LekoAdmin' && $store.getters.role != 'SuperAdmin'"
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.select_all')"
                      value=""
                    />
                    <v-radio
                      :disabled="!$store.getters.institutionExportEbayLeko && $store.getters.role != 'LekoAdmin' && $store.getters.role != 'SuperAdmin'"
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.on_ebay')"
                      :value="true"
                    />
                    <v-radio
                      :disabled="!$store.getters.institutionExportEbayLeko && $store.getters.role != 'LekoAdmin' && $store.getters.role != 'SuperAdmin'"
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.not_on_ebay')"
                      :value="false"
                    />
                  </v-radio-group>
                  <div
                    v-if="advancedSearchItems.eBayStatus === false"
                    class="d-flex ml-0 mt-3"
                  >
                    <v-checkbox
                      v-model="advancedSearchItems.no_shipping_cost"
                      :label="$t('parts.advanced_search.no_shipping_cost')"
                      dark
                      class="ma-0 pa-0"
                      color="grey lighten-4"
                    />
                  </div>
                </div>
              </v-layout>
              <!-- <div
                v-if="advancedSearchItems.eBayStatus === false"
                class="d-flex ml-2 mt-1"
              >
                <v-checkbox
                  v-model="advancedSearchItems.no_shipping_cost"
                  :label="$t('parts.advanced_search.no_shipping_cost')"
                  dark
                  class="ma-0 pa-0"
                  color="grey lighten-4"
                />
                <v-checkbox
                  v-model="advancedSearchItems.invalid_category"
                  :label="$t('parts.advanced_search.invalid_category')"
                  dark
                  class="ma-0 pa-0"
                  color="grey lighten-4"
                />
              </div> -->
            </v-flex>
            <!-- SEARCH BY PART CONDITION  -->
            <v-flex
              xs12
              lg4
              md4
              xl4
            >
              <v-layout
                column
                class="ml-2 mt-2"
              >
                <div class="subheading white--text">
                  {{ $t('parts.advanced_search.filter_for') }} <strong>{{ $t('parts.advanced_search.part_condition') }}</strong>
                </div>
                <div class="d-flex">
                  <v-radio-group
                    v-model="advancedSearchItems.isNewPart"
                    row
                    hide-details
                    ma-0
                    class="mr-0 pr-0"
                    style="width: 15%;"
                  >
                    <v-radio
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.select_all')"
                      value=""
                    />
                    <v-radio
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.new')"
                      :value="true"
                    />
                    <v-radio
                      color="grey lighten-4"
                      :label="$t('parts.advanced_search.used')"
                      :value="false"
                    />
                  </v-radio-group>
                </div>
              </v-layout>
            </v-flex>
            <!---------------------------------------STATUS---------------------------------------->
            <v-flex
              xs12
              sm6
              pa-2
            >
              <div class="subheading white--text mt-2 mb-2">
                {{ $t('parts.advanced_search.select_status') }}
              </div>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(status, index) in statusItems"
                  :key="index"
                  xs12
                  sm6
                  d-flex
                >
                  <v-checkbox
                    v-model="advancedSearchItems.status"
                    :label="status.title"
                    :value="status.value"
                    dark
                    class="ma-0 pa-0"
                    color="grey lighten-4"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!---------------------------------------QUALITY--------------------------------------->
            <v-flex
              xs12
              sm6
              pa-2
            >
              <div class="subheading white--text mt-2 mb-2">
                {{ $t('parts.advanced_search.select_quality') }}
              </div>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(quality, index) in qualityItems"
                  :key="index"
                  xs12
                  sm6
                  d-flex
                >
                  <span>
                    <v-checkbox
                      v-model="advancedSearchItems.quality"
                      :value="quality._id"
                      dark
                      class="ma-0 pa-0"
                      color="grey lighten-4"
                    >
                      <template v-slot:label>
                        <v-rating
                          v-model="quality.rating"
                          :color="advancedSearchItems.quality.includes(quality._id)
                            ? 'yellow': 'grey'"
                          small
                          :background-color="advancedSearchItems.quality.includes(quality._id)
                            ? 'yellow': 'grey'
                          "
                          readonly
                        />
                      </template>
                    </v-checkbox>

                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
        <!----------------------------------------------------------------------------------------->
        <v-layout
          align-center
          justify-center
          row
          wrap
        >
          <v-btn
            dark
            color="grey darken-4"
            @click="clearSearchFields"
          >
            {{ $t('parts.advanced_search.clear') }}
            <v-icon right>
              clear_all
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="grey darken-4"
            @click="searchMethod"
          >
            {{ $t('parts.advanced_search.search') }}
            <v-icon right>
              search
            </v-icon>
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */

import moment from 'moment';
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions
import axios from 'axios';
import getRequest from '../../api/getRequest';
import * as XLSX from 'xlsx';

export default {
  components: {
    'download-excel': JsonExcel,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  props: ['items', 'selected'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loadingArchive: false,
    loadingPdf: false,
    loadingSyncPrice: false,
    loadingDeletePart: false,
    searchTypes: [
      { label: 'Title', value: 'title' },
      { label: 'Other', value: 'other' },
      { lable: 'Multiple Titles', value: 'multipleTitles' },
      { label: 'ID', value: 'id' },
      { label: 'PRA', value: 'pra' },
    ],
    searchType: 'title',
    advancedSearch: false,
    search: '',
    foundPlate: false,
    canSearchPlates: false,
    plate: null,
    examplePlateFormat: ': (DG853SV)',
    searchTitle: null,
    searchMultipleTitles: null,
    titleCode: null,
    titleCodes: [],
    titles: [],
    brands: [],
    models: [],
    versions: [],
    titleGroups: [],
    brand: null,
    model: null,
    version: null,
    engine: null,
    year: null,
    yearRule: '',
    supply: null,
    supplies: [
      { code: 'B', title: 'Petrol' },
      { code: 'D', title: 'Diesel' },
      { code: 'G', title: 'LPG' },
    ],
    advancedSearchItems: {
      brand: null,
      model: null,
      version: null,
      institution: null,
      shipment: null,
      status: [1, 2, 2.1, 3, 4],
      quality: [],
      price: [0, 5000],
      startDate: '',
      endDate: '',
      noApplicability: false,
      noNotes: false,
      positionCode: '',
      eBayStatus: '',
      no_shipping_cost: true,
      invalid_category: true,
      isNewPart: '',
    },
    noApplicabilityDropdown: false,
    statusItems: [
      { title: 'Entered', value: 1 },
      { title: 'Returned', value: 2 },
      { title: 'Returned-Shop', value: 2.1 },
      { title: 'Re-Entered', value: 3 },
      { title: 'Found', value: 4 },
    ],
    qualityItems: [],
    institutions: [],
    shipments: [],
    loadingTitle: false,
    loadingMultipleTitles: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    loadingQuality: false,
    loadingInstitution: false,
    loadingShipment: false,
    loadingPlate: false,
    startDateMenu: false,
    endDateMenu: false,
    prices: [],
    preventAutoComplete: false,
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
  }),
  computed: {
    selectedItem() {
      return this.$props.selected;
    },
    server() { return this.$serverUri; },
  },
  watch: {
    search(value) { if (!value && this.advancedSearch === false) this.$emit('clear-search'); },
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCode = null;
        this.titles = [];
      }
    },
    searchMultipleTitles(value) {
      if (value) {
        this.loadingMultipleTitles = true;
        getRequest('/info-car/partgroups', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titleGroups = response.partgroups; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingMultipleTitles = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCodes = [];
        this.titleGroups = [];
      }
    },
    searchType() {
      this.titleCode = null;
    },
    advancedSearch(value) {
      if (!value) {
        this.advancedSearchItems = {
          brand: null,
          model: null,
          version: null,
          institution: null,
          shipment: null,
          status: [1, 2, 2.1, 3, 4],
          quality: [],
          price: [0, 5000],
          startDate: '',
          endDate: '',
          noApplicability: false,
          positionCode: '',
          eBayStatus: '',
          isNewPart: '',
          // no_shipping_cost: true,
          // invalid_category: true,
        };
        this.brands = [];
        this.models = [];
        this.versions = [];
        this.brand = null;
        this.model = null;
        this.version = null;
      } else {
        this.getQualities();
        this.getBrands();
        this.getInstitution();
        if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee' || this.$store.getters.role === 'SuperAdmin') {
          this.getInstitutions();
        } else {
          this.getShipments(this.$store.getters.institutionId);
        }
      }
    },
    'advancedSearchItems.institution': {
      handler(value) { if (value) this.getShipments(value); },
    },
    'advancedSearchItems.noApplicability': {
      handler(value) {
        if (!value && !this.advancedSearchItems.noNotes) this.noApplicabilityDropdown = false;
      },
    },
    'advancedSearchItems.noNotes': {
      handler(value) {
        if (!value && !this.advancedSearchItems.noApplicability) {
          this.noApplicabilityDropdown = false;
        }
      },
    },
    'advancedSearchItems.no_shipping_cost': {
      handler(value) {
        if (!value) {
          this.searchMethod();
        }
      },
    },
    'advancedSearchItems.invalid_category': {
      handler(value) {
        if (!value) {
          this.searchMethod();
        }
      },
    },
    brand(value) {
      if (value) {
        this.models = [];
        this.model = null;
        this.versions = [];
        this.version = null;
        // Make sure that we don't get the models if we are searching for a license plate.
        if (this.loadingPlate === false) this.getModels(value.brandCode);
        this.advancedSearchItems.brand = value.brandCode;
      } else { this.advancedSearchItems.brand = null; }
    },
    model(value) {
      if (value) {
        this.versions = [];
        this.version = null;
        // Make sure that we don't get the versions if we are searching for a license plate.
        if (this.loadingPlate === false) this.getVersions(value.brandCode, value.modelCode);
        this.advancedSearchItems.model = value.modelCode;
        // Set year rule.
        this.setYearRule(value.startingDate, value.endingDate);
      } else { this.advancedSearchItems.model = null; }
    },
    version(value) {
      if (value) {
        this.advancedSearchItems.version = value.versionCode;
        // Set year rule.
        this.setYearRule(value.startingDate, value.endingDate);
      } else { this.advancedSearchItems.version = null; }
    },
    engine(value) {
      this.versions = this.versions.reduce((acc, v) => {
        if (v.engineCode === value) acc.push(v);
        return acc;
      }, []);
    },
    year(value) {
      if (value) {
        this.$refs.form.validate();
        // filter models
        if (value.length === 4) { // Filter only when year is valid (4 characters long)
          this.filterModels();
          this.filterVersions();
        } else if (value.length !== 4 && this.brand && this.brand.brandCode) { // Reset models.
          this.models = this.getModels(this.brand.brandCode);
        }
      }
    },
    supply() {
      if (this.brand && this.brand.brandCode && this.model && this.model.modelCode) {
        // reset versions.
        this.versions = this.getVersions(this.brand.brandCode, this.model.modelCode);
      }
    },
  },
  mounted() {
    this.getLocale();
    // Generate prices list.
    this.generatePricesList();
    // Set initial year rule.
    this.setYearRule();
  },
  methods: {
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('parts.inventory.title');
      this.searchTypes[1].label = this.$i18n.t('parts.inventory.other');
      this.searchTypes[2].label = this.$i18n.t('parts.inventory.multiple_titles');
      this.searchTypes[3].label = this.$i18n.t('parts.inventory.id');
      this.searchTypes[4].label = this.$i18n.t('parts.inventory.pra');
      this.statusItems[0].title = this.$i18n.t('parts.status.entered');
      this.statusItems[1].title = this.$i18n.t('parts.status.returned');
      this.statusItems[2].title = this.$i18n.t('parts.status.returned_shop');
      this.statusItems[3].title = this.$i18n.t('parts.status.re_entered');
      this.statusItems[4].title = this.$i18n.t('parts.status.found');
      this.supplies[0].title = this.$i18n.t('parts.advanced_search.supply.petrol');
      this.supplies[1].title = this.$i18n.t('parts.advanced_search.supply.diesel');
      this.supplies[2].title = this.$i18n.t('parts.advanced_search.supply.gas');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    setYearRule(startingDate, endingDate) {
      const startingYear = startingDate ? new Date(startingDate).getFullYear() : 1900;
      const endingYear = endingDate ? new Date(endingDate).getFullYear() : new Date().getFullYear();
      this.yearRule = v => (v >= startingYear && v <= endingYear) || this.$i18n.t('parts.advanced_search.year_error');
    },
    generatePricesList() {
      this.prices = [];
      for (let i = 50; i <= 5000; i += 50) { this.prices.push(i); }
    },
    searchMethod() {
      if (!this.advancedSearchItems.positionCode) {
        this.advancedSearchItems.positionCode = '';
      }
      if (this.advancedSearchItems.eBayStatus === '') {
        // this.advancedSearchItems.eBayStatus = '';
      }
      if (this.advancedSearchItems.isNewPart === '') {
        // this.advancedSearchItems.isNewPart = '';
      }
      if (this.advancedSearch) { // ADVANCED SEARCH
        this.$emit('adv-search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
          advancedSearchFields: this.advancedSearchItems,
        });
      } else if (this.search.length > 0 || this.titleCode || this.titleCodes) {
        // REGULAR SEARCH
        this.$emit('search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
        });
      }
    },
    clearSearchFields() {
      this.search = '';
      this.titleCode = null;
      this.advancedSearchItems = {
        brand: null,
        model: null,
        version: null,
        institution: null,
        shipment: null,
        status: [1, 2, 2.1, 3, 4],
        quality: [],
        price: [0, 5000],
        startDate: '',
        endDate: '',
        noApplicability: false,
        eBayStatus: '',
        no_shipping_cost: true,
        invalid_category: true,
        isNewPart: '',
      };
      this.year = null;
      this.supply = null;
      this.plate = null;
      this.brand = null;
      this.model = null;
      this.version = null;
      this.engine = null;
      this.models = [];
      this.versions = [];
      this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
      this.$emit('clear-search');
    },

    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => {
          this.models = response.models;
          this.filterModels();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => {
          this.versions = response.versions;
          this.filterVersions();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },

    filterModels() {
      if (this.year && this.year.length === 4 && this.models && this.models.length > 0) {
        this.models = this.models.filter((model) => {
          const sYear = model.startingDate ? new Date(model.startingDate).getFullYear() : 1900;
          const eYear = model.endingDate
            ? new Date(model.endingDate).getFullYear() : new Date().getFullYear();

          return this.year >= sYear && this.year <= eYear;
        });
      }
    },
    filterVersions() {
      if (this.brand && this.brand.brandCode
        && this.model && this.model.modelCode && this.versions && this.versions.length > 0) {
        if (this.year) { // filter by year
          this.versions = this.versions.filter((version) => {
            const sYear = version.startingDate
              ? new Date(version.startingDate).getFullYear()
              : 1900;
            const eYear = version.endingDate
              ? new Date(version.endingDate).getFullYear()
              : new Date().getFullYear();

            return this.year >= sYear && this.year <= eYear;
          });
        }

        if (this.supply) { // filter by supply
          this.versions = this.versions.filter(version => version.supplyCode === this.supply);
        }
      }
    },

    scanQr() { this.$emit('scan-qr'); },
    exportData() {
      const items = [];
      this.$props.items.forEach((item) => {
        const itemObject = {
          Status: item.status === 1 ? 'Entered'
            : item.status === 2 ? 'Returned-Sales'
              : item.status === 2.1 ? 'Returned-Shop'
                : item.status === 3 ? 'Re-Entered'
                  : item.status === 4 ? 'Found'
                    : item.status === 5 ? 'Sold'
                      : item.status === 6 ? 'Lost'
                        : item.status === 7 ? 'Damaged'
                          : item.status === 8 ? 'Damaged-Sales'
                            : item.status === 8.1 ? 'Damaged-Shop'
                              : item.status === 9 ? 'Damaged-Lost'
                                : item.status === 0 ? 'Recycled'
                                  : '',
          ID: item.old_id ? item.old_id : item._id,
          Title: item.infoCar
              && item.infoCar.description
              && item.infoCar.description.descriptionId
              && item.infoCar.description.descriptionId.title
            ? item.infoCar.description.descriptionId.title[this.$store.getters.language]
            : '',
          'Manufacturer Code': item.manufacturer_code,
          Notes: item.notes,
          Quality: item.quality.title,
          Location: (item.shelf_id && item.shelf_data)
            ? `${item.shelf_data.shelf.room_name}-${item.shelf_data.shelf.location_code}: ${
              item.shelf_data.shelf.x}, ${item.shelf_data.shelf.y}`
            : '',
          // Location: (item.shelf_id && item.shelf_data)
          // ? `${item.shipment_id && item.shipment_id.name ? item.shipment_id.name : ''} ${item.shelf_data.shelf.room_name}-${item.shelf_data.shelf.location_code}: ${
          //   item.shelf_data.shelf.x}, ${item.shelf_data.shelf.y} ${item.shelf_data && item.shelf_data.repo ? `Mag: ${item.shelf_data.repo}` : ''}`
          // : '', // will add it later
          Price: `${item.price} ${item.currency}`,
          'Date Created': moment(item.date_created).format('DD-MM-YYYY LT'),
        };
        items.push(itemObject);
      });
      return items;
    },
    // async exportData1() {
    //   // when we need a certain number of items to get data
    //   //       const statusCodes = {
    //   //   1: 'EN01',
    //   //   2: 'RS02',
    //   //   2.1: 'RS21',
    //   //   3: 'RE03',
    //   //   4: 'FO04',
    //   //   5: 'SO05',
    //   //   6: 'LO06',
    //   //   7: 'DA07',
    //   //   8: 'DS08',
    //   //   8.1: 'DS81',
    //   //   9: 'DL09',
    //   //   0: 'RC00'
    //   // };
    //   const ids = [ ];

    //   const filteredItems = this.$props.items.filter(item =>
    //     ids.includes(item._id));
    //   console.log(filteredItems);
    //   console.log('filteredItems', filteredItems.length);
    //   const items = [];
    //   let parts = [];
    //   try {
    //     const response = await axios.post('http://localhost:3000/part/getSelectedPartsFromIds', { partIds: ids }, {
    //       headers: { authorization: localStorage.token },
    //     });
    //     parts = response.data.parts;
    //     console.log('response', response);
    //   } catch (error) {
    //     console.error('Error exporting Excel:', error);
    //   }

    //   parts.forEach((item) => {
    //     const itemObject = {
    //       Status: item.status === 1 ? 'EN01'
    //         : item.status === 2 ? 'RS02'
    //           : item.status === 2.1 ? 'RS21'
    //             : item.status === 3 ? 'RE03'
    //               : item.status === 4 ? 'FO04'
    //                 : item.status === 5 ? 'SO05'
    //                   : item.status === 6 ? 'LO06'
    //                     : item.status === 7 ? 'DA07'
    //                       : item.status === 8 ? 'DS08'
    //                         : item.status === 8.1 ? 'DS81'
    //                           : item.status === 9 ? 'DL09'
    //                             : item.status === 0 ? 'RC00'
    //                               : '',
    //       ID: item.old_id ? item.old_id.slice(-6) : item._id.slice(-6),
    //       Title: item.infoCar
    //           && item.infoCar.description
    //           && item.infoCar.description.descriptionId
    //           && item.infoCar.description.descriptionId.title
    //         ? item.infoCar.description.descriptionId.title.it
    //         : '',
    //       Group: item.infoCar
    //           && item.infoCar.description
    //           && item.infoCar.description.descriptionId
    //           && item.infoCar.description.descriptionId.category
    //         ? item.infoCar.description.descriptionId.category.it
    //         : '',
    //       Image: item.images[0].image ? item.images[0].image.split('/').pop() : '',
    //       'Oem Code': item.infoCar && item.infoCar.oemCode ? item.infoCar.oemCode : '',
    //       'Manufacturer Code': item.manufacturer_code,
    //       Notes: item.notes,
    //       Quality: item.quality ? item.quality.slice(-6) : '',
    //       Location: item.shelf_id ? item.shelf_id.slice(-6) : '',
    //       Warranty: item.warranty_id && item.warranty_id._id ? item.warranty_id._id.slice(-6) : '',
    //       'Ebay status': item.eBayStatus ? 'Yes' : 'No',
    //       'Google status': item.googleMerchantStatus ? 'Yes' : 'No',
    //       Price: `${item.price} ${item.currency}`,
    //       'Date Created': moment(item.date_created).format('DD-MM-YYYY LT'),
    //     };
    //     items.push(itemObject);
    //   });
    //   return items;
    // },
    async exportDataEbay() {
      const filteredItems = this.$props.items.filter(item => item.eBayStatus === false);
      const itemIds = filteredItems.map(item => item._id);
      try {
        const response = await axios.post(`${this.baseUrl}/ebay/prepare-items-excel`, { ids: itemIds, institutionId: this.$store.getters.institutionId }, {
          headers: { authorization: localStorage.token },
        });
        const excelData = response.data;
        const ws = XLSX.utils.json_to_sheet(excelData);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'ebay_items.csv';
        link.click();
      } catch (error) {
        console.error('Error exporting Excel:', error);
      }
    },
    syncEbay() {
      localStorage.setItem('synchronizeEbay', true);
      this.$emit('sync-with-ebay');
    },
    exportArchive() { this.$emit('export-archive'); },
    share() { this.$emit('share'); },
    deleteParts() { this.$emit('delete-parts'); },
    addEbayItems() {
      this.$emit('add-multiple-ebay', this.items);
    },
    deleteEbayItems() {
      this.$emit('delete-multiple-ebay', this.items);
    },
    addGoogleItems() {
      this.$emit('add-multiple-google', this.items);
    },
    deleteGoogleItems() {
      this.$emit('delete-multiple-google', this.items);
    },
    checkEbayStatus(items) {
      return items.every(item => item.eBayStatus === true);
    },
    checkGoogleStatus(items) {
      return items.every(item => item.googleMerchantStatus === true);
    },
    exportPdf() {
      if (this.advancedSearch) {
        this.$emit('export-pdf-advanced', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          advancedSearchFields: this.advancedSearchItems,
        });
      } else {
        this.$emit('export-pdf', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
        });
      }
    },
    startLoading(type) {
      if (type === 'archive') this.loadingArchive = true;
      if (type === 'pdf') this.loadingPdf = true;
      if (type === 'delete-part') this.loadingDeletePart = true;
    },
    stopLoading(type) {
      if (type === 'archive') this.loadingArchive = false;
      if (type === 'pdf') this.loadingPdf = false;
      if (type === 'delete-part') this.loadingDeletePart = false;
    },
    getQualities() {
      this.loadingQuality = true;
      getRequest('/quality/').then((response) => {
        this.qualityItems = response.qualities;
        this.advancedSearchItems.quality = [];
        this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
        this.loadingQuality = false;
      }).catch((error) => {
        console.log(error);
        this.loadingQuality = false;
      });
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        this.institutions = response.institutions;
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
    getInstitution() {
      getRequest('/institution', `id=${this.$store.getters.institutionId}`)
        .then((response) => {
          if (response.institution && response.institution.length > 0) {
            this.canSearchPlates = !!(response.institution[0].plates.remaining > 0);
          }
        })
        .catch((error) => { console.log(error); });
    },
    getShipments(institutionId) {
      this.loadingShipment = true;
      getRequest('/shipment/institution', `id=${institutionId}`).then((response) => {
        this.shipments = response.shipments;
        this.loadingShipment = false;
      }).catch((error) => {
        console.log(error);
        this.loadingShipment = false;
      });
    },
    searchPlate() {
      if (this.plate && this.plate.length > 0) {
        this.loadingPlate = true;
        getRequest(`/info-car/plate/${this.plate}`, '')
          .then(async (response) => {
            try {
              const { brands } = await getRequest('/info-car/brands');
              this.brands = brands;
              this.brand = await getRequest('/info-car/brand', `id=${response.brandId}`);

              const { models } = await getRequest('/info-car/models', `brand_code=${response.brandCode}`);
              this.models = models;
              this.model = await getRequest('/info-car/model', `id=${response.modelId}`);

              const { versions } = await getRequest('/info-car/versions', `brand_code=${response.brandCode}&model_code=${response.modelCode}`);
              this.versions = versions;
              this.version = await getRequest('/info-car/version', `id=${response.versionId}`);

              this.foundPlate = true;
              this.getInstitution();
            } catch (error) {
              this.$emit('alert', {
                message: this.$i18n.t('parts.advanced_search.plate_not_found'),
                type: 'error',
                timeout: 5000,
              });
              this.foundPlate = false;
            }
          })
          .catch((error) => {
            this.$emit('alert', {
              message: this.$i18n.t('parts.advanced_search.plate_not_found'),
              type: 'error',
              timeout: 5000,
            });
            this.foundPlate = false;
          })
          .finally(() => { this.loadingPlate = false; });
      }
    },
    syncPrice() {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const c = confirm(this.$i18n.t('confirm_alert'));
      if (c === true) {
        this.loadingSyncPrice = true;
        getRequest('/part/sync-price', `institution_id=${this.$store.getters.institutionId}`)
          .then((response) => { this.$emit('synced'); })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loadingSyncPrice = false; });
      }
    },
    openInfoCarCad() { this.$emit('infocar-cad', { brandCode: this.brand.brandCode, modelCode: this.model.modelCode, versionCode: this.version.versionCode }); },
    openCarPartsList() { this.$emit('car-parts-list', { car: { brand: this.brand, model: this.model, version: this.version } }); },
    openSearchList() { this.$emit('search-list'); },
    setTitle(data) { // Triggered by the car parts list and searchList
      // Force to search by title.
      this.searchType = 'title';
      // Set title.
      this.loadingTitle = true;
      getRequest('/info-car/part', `title_code=${data.code}`)
        .then((response) => {
          this.titles = [response.part];
          this.titleCode = response.part.partCode;
          // Search
          this.searchMethod();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingTitle = false; });
    },
    setGroup(data) { // Triggered by the searchList
      // Force search by multipleTitles
      this.searchType = 'multipleTitles';
      // Set Multiple titles
      this.loadingMultipleTitles = true;
      getRequest('/info-car/partgroup', `group_id=${data.groupId}`)
        .then((response) => {
          this.titleGroups = [response.partgroup];
          this.titleCodes = response.partgroup.codes;
          // Search
          this.searchMethod();
        })
        .catch(error => console.log(error))
        .finally(() => { this.loadingMultipleTitles = false; });
    },
    highlight(title, search) {
      let result = title || '';
      if (search) {
        const index = title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
        // mark the text.
        if (index > -1) result = [title.slice(0, index), '<mark>', title.slice(index, index + search.length), '</mark>', title.slice(index + search.length)].join('');
      }
      return `<span>${result}</span>`;
    },
    toggleNoApplicabilityDropdown() {
      this.noApplicabilityDropdown = !this.noApplicabilityDropdown;
      if (this.noApplicabilityDropdown) {
        this.advancedSearchItems.noApplicability = true;
        this.advancedSearchItems.noNotes = true;
      } else {
        this.advancedSearchItems.noApplicability = false;
        this.advancedSearchItems.noNotes = false;
      }
    },
    setCarDetails(title, brand, model, version) {
      return new Promise(async (resolve, reject) => {
        try {
          // title
          this.loadingTitle = true;
          const { part } = await getRequest('/info-car/part', `title_code=${title}`);
          this.titles = [part];
          this.titleCode = part.partCode;
          this.loadingTitle = false;
          // brand
          this.loadingBrand = true;
          const { brands } = await getRequest('/info-car/brands');
          this.brands = brands;
          this.brand = this.brands.find(i => i.brandCode === brand);
          this.loadingBrand = false;
          // model
          this.loadingModel = true;
          const { models } = await getRequest('/info-car/models', `brand_code=${this.brand.brandCode}`);
          this.models = models;
          this.model = this.models.find(i => i.modelCode === model);
          this.loadingModel = false;
          // version
          this.loadingVersion = true;
          const { versions } = await getRequest('/info-car/versions', `brand_code=${this.brand.brandCode}&model_code=${this.model.modelCode}`);
          this.versions = versions;
          this.version = this.versions.find(i => i.versionCode === version);
          this.loadingVersion = false;
          //
          resolve();
        } catch (error) {
          this.loadingTitle = false;
          this.loadingBrand = false;
          this.loadingModel = false;
          this.loadingVersion = false;
          reject(error);
        }
      });
    },
    setId(id) {
      this.searchType = 'id';
      this.search = id;
    },
  },
};
</script>
