<template>
  <v-dialog
    v-model="dialog"
    max-width="650px"
  >
    <v-card v-if="vehicle">
      <v-card-title class="red darken-2">
        <span class="headline"> {{ $t('vehicle_entry.shipments.label') }} </span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-layout
        row
        wrap
        align-center
        justify-center
      >
        <v-flex xs12>
          <v-alert
            :value="alert.value"
            :type="alert.type"
          >
            {{ alert.message }}
          </v-alert>
        </v-flex>

        <!---------------------------------------CAR DETAILS--------------------------------------->
        <v-flex xs12>
          <v-layout
            row
            wrap
            align-center
            justify-center
            class="ml-4 mr-4 mt-2 mb-4"
          >
            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.brand') }}</span>
              <span class="body-2">{{ vehicle.brand.title }}</span>
            </v-layout>


            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
              <span class="body-2">{{ vehicle.model.description }}</span>
            </v-layout>


            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
              <span class="body-2">{{ vehicle.version.description }}</span>
            </v-layout>
          </v-layout>
        </v-flex>
        <!----------------------------------------ADD PARTS---------------------------------------->
        <v-flex>
          <v-layout
            column
            align-center
            justify-center
            wrap
          >
            <span> {{ $t('vehicle_entry.shipments.main_qr_code') }} </span>
            <qr-code
              :size="60"
              :text="vehicle._id"
            />
            <h4>{{ vehicle._id.slice(vehicle._id.length-6, vehicle._id.length) }}</h4>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout column>
            <div
              v-if="selected"
              class="red--text body-2"
            >
              {{ $t('vehicle_entry.shipments.selectedAlert') }}
            </div>
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-text-field
                v-model="quantity"
                :label="$t('parts.insert_parts.empty_parts.quantity')"
                type="number"
                min="1"
                max="100"
                oninput="validity.valid||(value='');"
              />

              <v-btn
                :loading="loadingAdd"
                color="primary"
                @click="addShipmentEntry"
              >
                {{ $t('vehicle_entry.shipments.add') }}
                <v-icon right>
                  add
                </v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-flex>
        <!----------------------------------------SHIPMENT----------------------------------------->
        <v-flex
          v-if="vehicle.shipment.id && ($store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin')"
          xs12
        >
          <v-layout
            column
            align-center
            justify-center
          >
            <span> {{ $t('vehicle_entry.shipments.shipment') }} </span>
            <qr-code
              :size="60"
              :text="vehicle.shipment.id"
            />
            <h4>
              {{
                vehicle.shipment.id.slice(vehicle.shipment.id.length-6,
                                          vehicle.shipment.id.length)
              }}
            </h4>
          </v-layout>
        </v-flex>
        <!------------------------------------------TABLE------------------------------------------>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="vehicle.shipment.entries"
            :loading="loadingGet"
            item-key="_id"
            hide-actions
          >
            <v-progress-linear
              slot="progress"
              color="red darken-2"
              indeterminate
            />

            <template
              slot="items"
              slot-scope="props"
            >
              <td> {{ props.item.date | moment }} </td>
              <td> {{ props.item.parts.length }} </td>
              <td>
                <v-layout row>
                  <v-btn
                    icon
                    @click="viewQrCodes(vehicle, props.item.parts)"
                  >
                    <v-icon color="grey darken-2">
                      list
                    </v-icon>
                  </v-btn>

                  <v-btn
                    :loading="loadingRemove"
                    icon
                    @click="deleteShipmentEntry(props.item)"
                  >
                    <v-icon color="grey darken-2">
                      delete
                    </v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    dialog: false,
    loadingAdd: false,
    loadingGet: false,
    loadingRemove: false,
    vehicle: null,
    selected: null,
    quantity: 1,
    headers: [
      { text: 'Date', sortable: false, value: 'date' },
      { text: 'Number of QR', sortable: false, value: '' },
      { text: 'Actions', sortable: false, value: '' },
    ],
    alert: {
      value: false,
      type: 'warning',
      message: '',
    },
  }),
  watch: {},
  created() {
    this.setHeaders();
  },
  methods: {
    setHeaders() {
      this.headers[0].text = this.$i18n.t('vehicle_entry.shipments.date');
      this.headers[1].text = this.$i18n.t('vehicle_entry.shipments.number_of_qr');
      this.headers[2].text = this.$i18n.t('vehicle_entry.shipments.actions');
    },
    open(data) {
      this.getCar(data._id);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.vehicle = null;
    },
    hide() { this.dialog = false; },
    show() { this.dialog = true; },
    getCar(id) {
      this.loadingGet = true;
      getRequest('/car', `id=${id}`)
        .then((response) => {
          this.vehicle = response.car;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingGet = false; });
    },
    addShipmentEntry() {
      this.loadingAdd = true;
      putRequest('/car/entry', `id=${this.vehicle._id}`, {
        institutionId: this.$store.getters.institutionId,
        userId: this.$store.getters.userId,
        quantity: this.quantity,
        name: `${this.vehicle.brand.title} ${this.vehicle.model.description} ${this.vehicle.version.description}`,
        description: '',
        pra: this.vehicle.pra,
        withdrawnPIVA: this.vehicle.withdrawnPIVA,
      })
        .then((response) => {
          this.getCar(this.vehicle._id);
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingAdd = false; });
    },
    viewQrCodes(vehicle, parts) {
      this.$emit('view-qr-codes', { vehicle, parts });
    },
    deleteShipmentEntry(entry) {
      const c = confirm(this.$i18n.t('delete_alert')); // eslint-disable-line no-restricted-globals, no-alert
      if (c === true) {
        this.loadingRemove = true;
        deleteRequest('/car/entry', `id=${this.vehicle._id}&entry_id=${entry._id}&user_id=${this.$store.getters.userId}`)
          .then((response) => {
            this.getCar(this.vehicle._id);
          })
          .catch((error) => {
            console.log(error);
            this.alert = { value: true, type: 'warning', message: this.$i18n.t('vehicle_entry.shipments.deleteAlert') };
            setTimeout(() => { this.alert = { value: false, type: 'warning', message: '' }; }, 2000);
          })
          .finally(() => { this.loadingRemove = false; });
      }
    },
  },
};
</script>
